// import Sentry from your framework SDK (e.g. @sentry/react) instead of @sentry/browser
import * as Sentry from '@sentry/browser'
import html2canvas from '../utils/html2canvas/html2canvas.js'
import { Modal } from 'bootstrap'

/**
 * Setup Sentry
 */
export async function loadReplay() {
	Sentry.init({
		dsn: 'https://04af3cd2f4f44ad28348517e9f1c631e@metrics.easyverein.com/3',

		replaysSessionSampleRate: 0,

		replaysOnErrorSampleRate: 1,

		tracesSampleRate: 0.2,

		integrations: [
			new Sentry.Replay({
				maskAllText: true,
				blockAllMedia: true,
			}),
			new Sentry.BrowserTracing({
				tracePropagationTargets: [],
			}),
		],
	})
}

/**
 * Convert a data URI to a Blob
 *
 * @param {*} dataURI dataURI to convert
 * @returns {Blob} Blob object
 * @see https://stackoverflow.com/a/12300351/114558
 */
export function dataURItoBlob(dataURI) {
	let byteString
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1])
	} else {
		byteString = unescape(dataURI.split(',')[1])
	}
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
	const ia = new Uint8Array(byteString.length)
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i)
	}
	return new Blob([ia], { type: mimeString })
}

/**
 * Create a screenshot of the current page and display it in the bug report modal
 */
export function createBugReportScreenshot() {
	html2canvas(document.querySelector('#content-wrapper')).then((canvas) => {
		document.getElementById('sendBugReportModalPreview').innerHTML =
			'<img class="w-100" src="' + canvas.toDataURL('image/png') + '"/>'
	})
}

/**
 * Setup the bug report modal
 */
export function setupReportModal() {
	const modal = document.getElementById('showBugReportModal')
	if (modal) {
		modal.addEventListener('click', function (event) {
			createBugReportScreenshot()
			showModal('#sendBugReportModal')
		})

		document
			.querySelector('#sendFeedback')
			.addEventListener('submit', sendBugReport)
	}
}

/**
 * Send the bug report
 *
 * @param {Event} event - The submit event
 */
async function sendBugReport(event) {
	event.preventDefault()
	const form = event.target
	const formData = new FormData(form)
	formData.append(
		'screenshot',
		dataURItoBlob(
			document.getElementById('sendBugReportModalPreview').querySelector('img')
				.src
		)
	)
	await fetch(form.action, {
		method: 'POST',
		body: formData,
	})
	hideModal('#sendBugReportModal')
}

/**
 * Display the specified bs-5 modal
 *
 * @param {string} selector - The ID of the modal element to show
 */
function showModal(selector) {
	const modalElement = Modal.getOrCreateInstance(
		document.querySelector(selector)
	)
	modalElement.show()
}

/**
 * Display the specified bs-5 modal
 *
 * @param {string} selector - The ID of the modal element to show
 */
function hideModal(selector) {
	const modalElement = Modal.getOrCreateInstance(
		document.querySelector(selector)
	)
	document.getElementById('confirmReport').checked = false
	document.getElementById('errorDescription').value = ''
	modalElement.hide()
}
