// Font Awesome
import '@fortawesome/fontawesome-pro/js/fontawesome'
import '@fortawesome/fontawesome-pro/js/regular'
import '@fortawesome/fontawesome-pro/js/brands'

// Import components here, so that they are available
import '../components/selectUs.js'
import '../components/listBuilder.js'

// Import axe-core accessibility testing engine
import axe from 'axe-core'

import startWarnings, { warningDuck } from './warnings.js'
import { startLogger } from './logger.js'
import { loadReplay, setupReportModal } from './sentryReport.js'
import startCheckSessionActivity from './sessionActivityChecker.js'
import startShortCuts from './shortCuts.js'
import { getTasks } from '../utils/asyncTasksUtilities.js'
import { refreshOpenTasksBadge } from '../utils/openTasksBadgeUtilities'
import { registerDatepickerPlugin } from '../utils/queryBuilder.js'
import {
	eventListenerAdd,
	onclickElements,
	onSwipeDetected,
} from '../utils/helpers/eventHelpers.js'
import { addTooltips, hideTooltips } from '../utils/bootstrap/tooltip.js'
import { configurePasswordResetModal } from '../utils/passwords.js'
import { changeLanguage } from '../utils/translation.js'
import { initSearch } from '../components/searchEasyVerein.js'
import {
	switchBtnGroupVertical,
	forceDataTableRedraw,
} from '../utils/datatables.js'
import { getContextData, getGlobals } from '../utils/django.js'
import { hide } from '../utils/helpers/show.js'
import { toggleRequiredInputValidity } from '../utils/input.mjs'
import { patch } from '../api/crud.js'
import { initializeFront } from '../utils/frontChat.js'
import { triggerChangeDateEvent } from '../utils/datePickerSettings.js'
import { isInViewport } from '../utils/animation/scroll.js'
import { copyToClipboard } from '../utils/helpers/clipboardHelper.js'
import { setupTagManager, setupGlobalSiteTag } from '../utils/analytics.js'

/**
 * Equivalent of the jQuery ready function.
 * Execute code ones the DOM is ready.
 *
 * This should be used last in each file, as to make sure all the global variables are definined.
 *
 * @param {Function} func - Function the run once the DOM is ready, usually the `start`-function of your endpoint
 * @param {boolean} isLitePage - true if the page is outside the app like appRegister (all pages which are using baseLite.html)
 */
export default function ready(func, isLitePage = false) {
	try {
		const wrapper = () => {
			if (!isActivePage(func.name)) return
			if (isLitePage) {
				onEveryLitePage()
			} else {
				onEveryPage()
			}
			func()
		}
		if (document.readyState !== 'loading') {
			wrapper()
		} else {
			document.addEventListener('DOMContentLoaded', wrapper)
		}
		loadReplay()
		setupReportModal()
	} catch (e) {
		console.error(e)
	}
}

/**
 * Checks if the function name is matching the body id and therefore is the correct page to run.
 *
 * @param {string} functionName - name of the function to check
 * @returns {boolean} true if the page is matching the body id.
 */
function isActivePage(functionName) {
	if (!functionName.startsWith('start')) {
		console.error(
			`The function "${functionName}" was called with "ready()". It does not follow the rule of calling the entrypoint "startApp…"!`
		)
		return false
	}
	const currentPage = document.body.id
	if (
		functionName.toLowerCase().replace('start', '') !==
		currentPage.toLowerCase()
	) {
		console.warn(
			`The function "${functionName}" was called, but it is not the entrypoint of the current page ("${document.body.id}"). This might be a circular import.`
		)
		return false
	}
	return true
}

/**
 * Code that needs to be executed on every page.
 *
 * Please make sure it _really_ is needed on every page before adding to it.
 */
function onEveryPage() {
	startWarnings()
	startShortCuts()
	startLogger(false)
	setEventListeners()
	addCopySupportPinListener()
	initDataTableRedrawListener()
	mobileToolbarBottomFailsafe()
	hideDropmenuButton()
	switchBtnGroupVertical()
	initializeFront()
	startCheckSessionActivity()
	refreshOpenTasksBadge()
	preventSubmitOnNoSubmitForms()
	registerDatepickerPlugin()
	configurePasswordResetModal()
	if (!getContextData('isTaxUser')) {
		initSearch()
	}
	getTasks()
	setCopyrightToCurrentYear()
	scrollSideMenuActiveElementIntoView()
	addTooltips('[data-bs-toggle="tooltip"]') // Keep this at the end to make sure all possible tooltips are activated
	if (!getContextData('hide_warningduck')) {
		setTimeout(warningDuck, 3000)
	}
	if (getGlobals().isDebug && !getContextData('skip_axe_accessibility_tests')) {
		runAxe()
	}
}

/**
 * Code that needs to be executed on every page which is outside app.
 *
 * Please make sure it _really_ is needed on every page before adding to it.
 */
function onEveryLitePage() {
	setupGlobalSiteTag()
	setupTagManager()
	startWarnings()
	startShortCuts()
	startLogger(false)
	setEventListeners()
	initializeFront(true)
	startCheckSessionActivity()
	preventSubmitOnNoSubmitForms()
	registerDatepickerPlugin()
	setCopyrightToCurrentYear()
	addTooltips('[data-bs-toggle="tooltip"]') // Keep this at the end to make sure all possible tooltips are activated
	if (!getContextData('hide_warningduck')) {
		setTimeout(warningDuck, 3000)
	}
	if (getGlobals().isDebug && !getContextData('skip_axe_accessibility_tests')) {
		runAxe()
	}
}

/**
 * Sets the copyright year notice to the curreny year.
 */
function setCopyrightToCurrentYear() {
	const year = document.querySelector('#year')
	if (year) {
		year.innerText = new Date().getFullYear()
	}
}

/**
 * Makes sidebar menu more scrollable as failsafe for mobile browsers with bottom toolbar that could potentially cover up last menu points
 */
function mobileToolbarBottomFailsafe() {
	const width = document.body.clientWidth
	const sidebarMenu = document.querySelector('.sidebar-menu')
	if (
		width < 450 &&
		sidebarMenu &&
		sidebarMenu.lastChild &&
		sidebarMenu.lastChild.outerHTML !== '<br>'
	) {
		const addHTML = '<br><br><br>'
		sidebarMenu.innerHTML += addHTML
	}
}

/**
 * Configures forms with the `.form-no-submit` class to automatically preventDefault.
 */
function preventSubmitOnNoSubmitForms() {
	document.querySelectorAll('.form-no-submit').forEach((form) =>
		form.addEventListener('submit', (e) => {
			e.preventDefault()
			return false
		})
	)
}

/**
 * Set the event listeners for the base.html
 */
function setEventListeners() {
	const onclick = {
		'#changeLanguageDropUp .dropdown-menu button': (event) =>
			changeLanguage(event.currentTarget.dataset.language),
		'.main-menu-toggle': () => toggleMainMenu(),
		'#ownProfilePicture': (event) => decollapseOrRedirect(event),
		'.deCollapseForSearch': () => deCollapseForSearch(),
		'#main-sidebar': (e) => {
			if (e.target.classList.contains('overlay')) {
				document.body.classList.toggle('collapse-main-mobile')
			}
		},
	}

	onclickElements(onclick)

	onSwipeDetected(
		window,
		(swipeDirection) => {
			if (swipeDirection === 'right') {
				document.body.classList.remove('collapse-main-mobile')
			}
			if (swipeDirection === 'left') {
				document.body.classList.add('collapse-main-mobile')
			}
		},
		false
	)

	window.onresize = () => {
		mobileToolbarBottomFailsafe()
	}

	window.onbeforeunload = () => {
		if (document.querySelector('[data-waschanged="true"]')) {
			return ''
		}
	}

	document.querySelectorAll('[required]').forEach((field) => {
		field.addEventListener('focusout', toggleRequiredInputValidity)
	})

	// Observing if the search is used, if the search is not used and the sidebar would be otherwise collapsed, collapse it again
	const observer = new IntersectionObserver(checkCollapseAfterFocusOut)
	const searchInput = document.querySelector('#searchEasyVerein')
	if (searchInput) {
		observer.observe(searchInput)
	}

	eventListenerAdd(
		{
			'.modal': (event) => {
				triggerChangeDateEvent('.datemask')
			},
		},
		'show.bs.modal'
	)
}

/**
 * Copy the support pin to the clipboard
 */
function addCopySupportPinListener() {
	const copySupportPinElement = document.querySelector('#supportPinCopy')
	if (copySupportPinElement) {
		copySupportPinElement.addEventListener('click', (event) =>
			copyToClipboard(event)
		)
	}
}

/**
 * Decollpaseing the sidebar if it is collapsed or redirecting the user to his own profile
 *
 * @param {Event} event - Default JS onclick event object
 */
function decollapseOrRedirect(event) {
	if (document.body.classList.contains('collapse-main')) {
		event.preventDefault()
		toggleMainMenu(false)
	}
}

/**
 * Check if the search is used and if it's not collapse the sidebar again if it should be
 *
 * @param {Array} entries - An array containing the observers element entries
 * @param {IntersectionObserver} observer - The IntersectionObserver object
 */
function checkCollapseAfterFocusOut(entries, observer) {
	entries.forEach((entry) => {
		if (!entry.isIntersecting) {
			// Its hidden
			if (document.body.dataset.collapse === 'True') {
				document.body.classList.add('collapse-main')
			}
		}
	})
}

/**
 * Decollapseing the left menu bar and sets the focus on the search input
 */
function deCollapseForSearch() {
	toggleMainMenu(false)
	document.querySelector('#headerSearchbarDropdown').click()
	document.querySelector('#headerSearchbarDropdown input').focus()
}

/**
 * listener to fix broken table headers of datatable
 */
function initDataTableRedrawListener() {
	document
		.querySelector('#main-sidebar-collapsible')
		.addEventListener('transitionend', (event) => {
			if (event.target.id === 'main-sidebar-collapsible') {
				forceDataTableRedraw(event)
			}
		})
	window.addEventListener('load', () => {
		window.dispatchEvent(new Event('resize'))
	})
}

/**
 * Click callback for the sidebar-toggle button that toggles visibility of the main-sidebar
 * Also saving this setting in the personal settings
 *
 * @param {boolean} [saveSetting=true] - A boolean indicating if the collapse toggle should be changed the in personal setting
 */
function toggleMainMenu(saveSetting = true) {
	const width = window.innerWidth
	const bootstrapBreakpointMd = 768 // See also base.html
	hideTooltips('.main-menu-toggle')
	if (width >= bootstrapBreakpointMd) {
		document.body.classList.toggle('collapse-main')
		// Changing the personal setting
		const personalSettingsHideSidebar =
			document.body.classList.contains('collapse-main')
		if (saveSetting) {
			patch('member', getContextData('user_id'), {
				personalSettingsHideSidebar,
			})
			document.body.dataset.collapse = personalSettingsHideSidebar
				? 'True'
				: 'False'
		}
	} else {
		document.body.classList.toggle('collapse-main-mobile')
	}
}

/**
 * Hide dropmenu button when there are no dropmenu items
 */
function hideDropmenuButton() {
	if (document.querySelector('.navbar-nav').childElementCount === 0) {
		hide('.navbar-toggler')
	}
}

/**
 * Scrolls the active side menu point into view
 */
function scrollSideMenuActiveElementIntoView() {
	const element = document.querySelector('.sidebar-menu .active')
	if (element && !isInViewport(element)) {
		element.scrollIntoView()
	}
}

/**
 * run axe-core accessibility testing engine
 * https://github.com/dequelabs/axe-core
 */
function runAxe() {
	axe
		.run()
		.then((results) => {
			const violations = results.violations
			if (violations.length) {
				violations.forEach((violation) => {
					const impact = violation.impact
					const axeMessage = `[AXE] ${impact} accessibility issue:`
					if (impact === 'critical' || impact === 'serious') {
						console.error(axeMessage, violation)
					} else {
						console.warn(axeMessage, violation)
					}
				})
			}
		})
		.catch((err) => {
			console.error('Something bad happened:', err.message)
		})
}
