let timeout

/**
 * Exectues the callback function after the delay is over.
 * If the debounce function is called within the delay, the delay will be refreshed.
 *
 * @param {Function} callback - The function that should be called after the delay was over.
 * @param {string | number} delay - The delay, in miliseconds (use `delay` from `delays.js` for consistency).
 * @returns {Function} A function which will trigger the callback.
 */
export function debounce(callback, delay) {
	return () => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			timeout = null
			callback.apply(this, arguments)
		}, delay)
	}
}

/**
 * Exectues the callback function after the delay is over
 * If the debounce function is called within the delay, the delay will be refreshed
 * Will return the return value of the callback function even if the callback is async
 *
 * @param {Function} callback - The function that should be called after the delay was over
 * @param {string | number} delay - The delay, in miliseconds (use `delay` from `delays.js` for consistency)
 * @returns {Function} A asyncronus function which will trigger the callback
 */
export function asyncDebounce(callback, delay) {
	return () => {
		return new Promise((resolve, reject) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				resolve(callback.apply(this, arguments))
			}, delay)
		})
	}
}
