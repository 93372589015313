import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-bs5'
import { getContextData, getGlobals } from './django'
import { german } from '../app/datatables-german'
import { setCookie, getCookie } from '../utils/cookies'
import { escapeHtml, makeHtmlHumanReadable } from './html'
import { gettext } from './translation'
import { debounce } from './debounce'
import { highlightMassActionData } from './massAction'
import { delay } from './delays'
import { hide, show, toggleVisibility } from './helpers/show'
import { preventCloseOnFocus, deFocus } from './helpers/focus'
import { internalError, successMessage } from '../globals/messages'
import { getReadableTextColor } from '../design/colors'
import { renderGoToDataTablePage } from '../components/goToTablePage'
import { eventListenerAdd } from './helpers/eventHelpers'
import { headers } from '../utils/helpers/apiHelpers'
import { alertDanger } from '../globals/alerts'

/**
 * Adds the `language` key to the options
 *
 * @param {object} options - Datatables configuration object
 * @returns {object} Datatables configuration object
 */
export function setLanguage(options) {
	const globals = getGlobals()
	if (globals.languageCode === 'de') {
		options.language = german
	}
	if (options.language) {
		options.language.search = ''
	}
	return options
}

/**
 * Checks if a selector is a DataTable
 *
 * @param {string} selector - DOM selector
 * @returns {boolean} true if it is a DataTable, else false
 */
export function isDataTable(selector) {
	return $.fn.DataTable.isDataTable(selector)
}

/**
 * Deletes the DataTable if `selector` is one. Does nothing otherwise
 *
 * @param {string} selector - DOM selector
 */
export function isDataTableThenDelete(selector) {
	if (isDataTable(selector)) {
		$(selector).DataTable().destroy()
	}
}

/**
 * Retrieves recordsDisplay from the DataTable if `selector` is one. Does nothing otherwise
 *
 * @param {string} selector - DOM selector
 * @returns {number} number of total records after filtering
 */
export function getRecordsDisplay(selector) {
	return $(selector).DataTable().page.info().recordsDisplay
}

/**
 * Retrieves recordsTotal from the DataTable if `selector` is one. Does nothing otherwise
 *
 * @param {string} selector - DOM selector
 * @returns {number} number of total records
 */
export function getRecordsTotal(selector) {
	return $(selector).DataTable().page.info().recordsTotal
}

/**
 * Check if a search is active or not
 *
 * @param {string} selector - DOM selector
 * @returns {boolean} whether a search is active or nit
 */
export function isFilteredBySearch(selector) {
	const urlParams = new URLSearchParams(window.location.search)
	const currentSearch = $(selector).DataTable().settings()[0]
		.oPreviousSearch.sSearch
	return currentSearch !== '' || urlParams.get('search') !== null
}

/**
 * Stores current DataTable page number in a cookie
 *
 * @param {string} tableId - table ID selector
 * @param {string} cookieName - name of the cookie
 */
export function ensureTablePaging(tableId, cookieName) {
	const dataTable = $(tableId).DataTable()
	let page = dataTable.page() + 1
	if (dataTable.data().length === 0) {
		page = 1
	}
	setCookie(cookieName, page)
}

/**
 * Retrieves the current page of the table from the cookie
 *
 * @param {number} tableLength - current number of table pages
 * @param {string} cookieName - name of the cookie
 * @returns {number} stored page number
 */
export function setTableDisplayStart(tableLength, cookieName) {
	const cookieValue = getCookie(cookieName)
	const page = cookieValue !== '' ? cookieValue : 1
	return page === 1 ? 0 : tableLength * page - tableLength
}

/**
 * Makes the tables horizontal scrollbar sticky
 *
 * @param {string} tableSelector - selector of the table which need the horizontal scrollBar
 * @param {string} scrollAreaSelector - area for horizontal scrolling
 */
export function initStickyHorizontalTableScrollbar(
	tableSelector = '#usersTable',
	scrollAreaSelector = '.dataTables_scrollBody'
) {
	// Function for table scroll bar
	$(function ($) {
		const fixedBarTemplate = '<div class="fixed-scrollbar"><div></div></div>'
		const fixedBarCSS = {
			display: 'none',
			overflowX: 'scroll',
			position: 'fixed',
			width: '100%',
			bottom: 0,
		}

		$(scrollAreaSelector).each(function () {
			const $container = $(this)
			// destroy scrollbar if was already initialized
			document.querySelector('.fixed-scrollbar')?.remove()
			const $bar = $(fixedBarTemplate).appendTo($container).css(fixedBarCSS)
			$bar.scroll(function () {
				$container.scrollLeft($bar.scrollLeft())
			})

			$bar.data('status', 'off')
		})

		const fixSize = function () {
			$('.fixed-scrollbar').each(function () {
				const $bar = $(this)
				const $container = $bar.parent()

				$bar.children('div').height(1).width($container[0].scrollWidth)
				$bar.width($container.width()).scrollLeft($container.scrollLeft())
			})

			$(window).trigger('scroll.fixedbar')
		}

		$(window).on('load.fixedbar resize.fixedbar', function () {
			switchBtnGroupVertical()
			fixSize()
		})

		$(tableSelector).on('draw.dt', function () {
			switchBtnGroupVertical()
			fixSize()
			if (document.querySelector('.fixed-scrollbar')) {
				const dataTableWidth =
					document.querySelector(scrollAreaSelector).offsetWidth
				document.querySelector('.fixed-scrollbar').style.width =
					dataTableWidth + 'px'
				const dataTableScrollWidth =
					document.querySelector(scrollAreaSelector).scrollWidth
				document.querySelector('.fixed-scrollbar').firstChild.style.width =
					dataTableScrollWidth + 'px'
			}
		})

		let scrollTimeout = null

		$(window).on('scroll.fixedbar', function () {
			clearTimeout(scrollTimeout)
			scrollTimeout = setTimeout(function () {
				$(scrollAreaSelector).each(function () {
					const $container = $(this)
					const $bar = $('.fixed-scrollbar')
					if ($container[0].scrollWidth > $container.width()) {
						const containerOffset = {
							top: $container.offset().top,
							bottom: $container.offset().top + $container.height(),
						}
						const windowOffset = {
							top: $(window).scrollTop(),
							bottom: $(window).scrollTop() + $(window).height(),
						}
						if (
							containerOffset.top > windowOffset.bottom ||
							windowOffset.bottom > containerOffset.bottom
						) {
							if ($bar.data('status') === 'on') {
								$bar.hide().data('status', 'off')
							}
						} else {
							if ($bar.data('status') === 'off') {
								$bar.show().data('status', 'on')
								$bar.scrollLeft($container.scrollLeft())
								// toggle shadow of sticky-actions-bar
								document
									.querySelectorAll('.sticky-actions-bar')
									.forEach((sab) => {
										sab.classList.add('sticky-actions-bar-shadow')
									})
							}
						}
					} else {
						if ($bar.data('status') === 'on') {
							$bar.hide().data('status', 'off')
						}
					}
				})
			}, 50)
		})
		$(window).trigger('scroll.fixedbar')
	})
}

/**
 * Build the DataTable for Addressbook or Members (usersTable)
 *
 * @param {string} kind - The kind of the table, either 'OrgUser', 'Address' or 'Invoice'
 * @param {number} defaultSort - The default sort number to begin with
 * @param {string} sortDir - A string containing either 'desc' or 'asc'
 * @param {number|string} tableLength - The length of a page of the table
 * @param {boolean?} destroyExistingTable - remove any existing DataTables before creating a new one
 * @param {Function} drawCallBack - Optional drawCallBack function
 * @param {Array} tableFields - list of visible columns
 * @param {Array} availableFields - list of all possible columns
 * @param {Function} rowCallBack - Optional rowCallBack function
 * @param {object} renders - Renders used to format the data retrived from backend
 * @param {string} dataTableId - the id of the DataTable only needed for renderGoToTablePage
 * @param {string} userId - the id of the current user (needed for the page number cookie for the member, address and inventory tables)
 * @returns {object|undefined} - Returns either null if any variable is not declared or the datatable object
 */
export function buildDataTable(
	kind,
	defaultSort,
	sortDir,
	tableLength = 20,
	destroyExistingTable = false,
	drawCallBack,
	tableFields,
	availableFields,
	rowCallBack,
	renders,
	dataTableId = undefined,
	userId = undefined
) {
	if (!tableFields) {
		tableFields = getContextData('tableFieldsData')
	}
	if (!availableFields) {
		availableFields = getContextData('availableTableFields')
	}

	// filter out all non existent fields
	const availableFieldIds = new Set(availableFields.map((field) => field.id))
	tableFields = tableFields.filter((fieldId) => availableFieldIds.has(fieldId))

	const customFieldTypes = getContextData('customFieldsData')

	if (!tableFields.length || !availableFields.length) {
		return
	}
	if (!['OrgUser', 'Address', 'Invoice', 'InventoryObject'].includes(kind)) {
		return
	}

	if (destroyExistingTable) {
		isDataTableThenDelete('#usersTable')
	}
	tableLength = Number.parseInt(tableLength)
	const columns = buildTableHeader(
		kind,
		tableFields,
		availableFields,
		customFieldTypes,
		renders
	)

	const extendedDrawCallBack = () => {
		const table = document.querySelector('#usersTable')
		const groupPills = table.querySelectorAll('.rounded-pill')
		for (const pill of groupPills) {
			const color = pill.style.background
			if (color) {
				pill.style.color = getReadableTextColor(color)
			}
		}
		if (drawCallBack) {
			drawCallBack()
		}
	}

	const config = getConfig(
		kind,
		columns,
		tableFields,
		defaultSort,
		sortDir,
		tableLength,
		extendedDrawCallBack,
		rowCallBack,
		dataTableId,
		userId
	)
	return createDataTable(config)
}

/**
 * Shared default config for DataTables.
 *
 * @param {string} kind - The kind of the table, either 'OrgUser', 'Address' or 'Invoice'
 * @param {Array<Array>} order - list of list, inner lists are of the form `[columnNumber, sortDirection]`
 * @param {Array} columns - An array containing some objects with orderable true or false
 * @param {string} dataTableId - the id of the DataTable only needed for renderGoToTablePage
 * @returns {object} DataTable configuration
 */
export function getDataTablesDefaultConfig(
	kind,
	order,
	columns,
	dataTableId = undefined
) {
	const searchParam = document.querySelector('#searchDataTable').value || ''
	return setLanguage({
		orderCellsTop: true,
		serverSide: true,
		processing: true,
		info: true,
		ajax: {
			url: `/app/api/tabledata/${kind}`,
		},
		scrollX: true,
		order: order,
		dom: 'lrt<"m-0 row"<"col-6"p><"col-6"<"text-end mx-4"i>>>', // 'f' would add the search field
		autoWidth: true,
		search: { search: searchParam },
		columns: columns,
		lengthChange: false,
		initComplete: () => {
			configureSearch()
			if (dataTableId) {
				renderGoToDataTablePage(dataTableId)
				// Check if the page that should be set exists and if it doesn't start at the first page
				const selector = `#${dataTableId}`
				const table = $(selector).DataTable()
				const numPages = table.page.info().pages
				const currentPage = table.page()
				if (currentPage > numPages) table.page(0).draw('page')
			}
		},
		stateSave: true,
		stateSaveCallback: (settings, data) => {
			localStorage.setItem(
				`DataTables-${settings.sInstance}${window.location.pathname}`,
				JSON.stringify(data)
			)
		},
		stateLoadCallback: (settings, callback) => {
			const perfEntries = performance.getEntriesByType('navigation')
			if (perfEntries.length && perfEntries[0].type === 'back_forward') {
				const data = localStorage.getItem(
					`DataTables-${settings.sInstance}${window.location.pathname}`
				)
				callback(JSON.parse(data))
			} else {
				callback()
			}
		},
		headerCallback: () => {
			if (dataTableId) {
				const currentDataTable = document.querySelector(`#${dataTableId}`)
				buildSearchColumnSelection(currentDataTable.dataset.tableId)
			}
		},
	})
}

/**
 * Returns the configuration of all `#usersTables` that are generated `createDataTable()`
 *
 * @param {string} kind - The kind of the table, either 'OrgUser', 'Address' or 'Invoice'
 * @param {Array} columns - An array containing some objects with orderable true or false
 * @param {Array} tableFields - An array containing all table fields of the Address or OrgUser
 * @param {number} defaultSort - The default sort number to begin with
 * @param {string} sortDir - A string containing either 'desc' or 'asc'
 * @param {number} tableLength - The length of a page of the table
 * @param {Function} drawCallBack - Optional drawCallBack function
 * @param {Function} rowCallBack - Optional rowCallBack function
 * @param {string} dataTableId - the id of the DataTable only needed for renderGoToTablePage
 * @param {string} userId - the id of the current user (needed for the page number cookie for the member, address and inventory tables)
 * @returns {object} a DataTables configuration
 */
function getConfig(
	kind,
	columns,
	tableFields,
	defaultSort,
	sortDir,
	tableLength,
	drawCallBack,
	rowCallBack,
	dataTableId = undefined,
	userId = undefined
) {
	const config = getDataTablesDefaultConfig(
		kind,
		[[defaultSort, sortDir]],
		columns,
		dataTableId
	)
	let display = ''
	if (userId) {
		if (kind === 'OrgUser') {
			display = `appMembersUserTablePage_user${userId}`
		} else if (kind === 'Address') {
			display = `appAddressbookTablePage_user${userId}`
		} else if (kind === 'InventoryObject') {
			display = `appInventoryTablePage_user${userId}`
		} else if (kind === 'ArticleObject') {
			display = `appArticleTablePage_user${userId}`
		}
	}
	config.ajax.data = (d) => {
		d.tableFields = tableFields
	}
	config.drawCallback = () => {
		if (drawCallBack) {
			drawCallBack()
		}
	}
	config.pageLength = tableLength
	config.rowCallback = (row, data) => {
		if (data.length) {
			row.id = data[data.length - 1]
		} else {
			row.id = data.DT_RowId
		}
		highlightMassActionData(row)
		if (rowCallBack) {
			rowCallBack(row, data)
		}
	}
	config.displayStart = setTableDisplayStart(tableLength, display)

	return config
}

/**
 * Creates the table header for `#usersTable` DataTables.
 *
 * @param {string} kind - The kind of the table, either 'OrgUser', 'Address' or 'Invoice'
 * @param {Array} tableFields - An array containing all table fields of the Address or OrgUser
 * @param {Array} availableFields - An array containing all table fields that are available
 * @param {object} customFieldTypes - An object containing the customField settings_type, name and additional
 * @param {object} renders - Renders used to format the data retrived from backend
 * @returns {Array} - An array containing some objects with orderable true or false
 */
function buildTableHeader(
	kind,
	tableFields,
	availableFields,
	customFieldTypes,
	renders
) {
	document.querySelector('#usersBody').innerHTML = ''
	let head = '<tr>'
	const columns = []
	if (kind !== 'OrgUser' && kind !== 'InventoryObject') {
		head += '<th></th>'
		columns.push({ orderable: false })
	}
	for (const i in tableFields) {
		for (const j in availableFields) {
			if (availableFields[j].id === tableFields[i]) {
				head += `<th>${escapeHtml(availableFields[j].name)}</th>`
				if (renders && renders[availableFields[j].id]) {
					columns.push({
						orderable: availableFields[j].orderable === 'true',
						render: renders[availableFields[j].id],
					})
				} else {
					columns.push({ orderable: availableFields[j].orderable === 'true' })
				}
				if (
					availableFields[j].id === 'group' ||
					availableFields[j].id === 'groups'
				) {
					columns[columns.length - 1].className = 'table-group-width'
				}
				// Type 'f' is ckeditor textarea
				if (
					availableFields[j].id.includes('customfield_') &&
					availableFields[j].id.split('_')[1] in customFieldTypes &&
					customFieldTypes[availableFields[j].id.split('_')[1]]
						.settings_type === 'f'
				) {
					columns[columns.length - 1].render = (value) => {
						return makeHtmlHumanReadable(value)
					}
				}
			}
		}
	}

	if (kind === 'InventoryObject') {
		columns.push({
			orderable: false,
			className: 'sticky-actions-bar bg-body',
			width: '200px',
			render: renders.action,
		})
	} else {
		columns.push({
			orderable: false,
			className: 'sticky-actions-bar bg-body',
			width: '100px',
		})
	}
	if (kind === 'OrgUser' || kind === 'InventoryObject') {
		head += '<th></th></tr>'
	} else if (kind === 'Invoice') {
		const personalSettingsInvoiceTableLength =
			getContextData('personalSettingsInvoiceTableLength') || 10
		head += `<th><select class="form-select" id="setTableLengthInTable">
				<option value="10" ${
					personalSettingsInvoiceTableLength === 10 ? 'selected' : ''
				}>10</option>
				<option value="20" ${
					personalSettingsInvoiceTableLength === 20 ? 'selected' : ''
				}>20</option>
				<option value="30" ${
					personalSettingsInvoiceTableLength === 30 ? 'selected' : ''
				}>30</option>
				<option value="50" ${
					personalSettingsInvoiceTableLength === 50 ? 'selected' : ''
				}>50</option>
				<option value="75" ${
					personalSettingsInvoiceTableLength === 75 ? 'selected' : ''
				}>75</option>
				<option value="100" ${
					personalSettingsInvoiceTableLength === 100 ? 'selected' : ''
				}>100</option>
			</select></th>`
	} else {
		head += `<th style="white-space: nowrap"><span class="hidden" >${gettext(
			'Adressoptionen'
		)}</span></th></tr>`
	}

	document.querySelector('#usersHead').innerHTML = head

	return columns
}

/**
 * Creates the actual `#usersTable` DataTable
 *
 * @param {object} config - A DataTables configuration
 * @returns {object} a datatable instance
 */
function createDataTable(config) {
	return $('#usersTable').DataTable(config)
}

/**
 * Connects the search input from the top navigation to the datatable
 */
export function configureSearch() {
	const inputElement = $('#searchDataTable')
	inputElement.off()
	inputElement.on('input', debounce(search, delay.default))
	inputElement.on('input', () => toggleEmptySearchButton())
	configureDeleteSearchButton()
	document
		.querySelector('#searchDataTable')
		.addEventListener('focus', (event) => {
			show('#searchColumns')
		})
	eventListenerAdd(
		{
			'#tableSearchDropdown': (event) =>
				preventCloseOnFocus(event, '#searchColumnsList', '#searchDataTable'),
		},
		'hide.bs.dropdown'
	)
}

/**
 * Build the list of columns that can be (un-)selected for the search in a table
 *
 * @param {string} tableDatasetId -- The ID of the table ID from the table's dataset
 */
function buildSearchColumnSelection(tableDatasetId) {
	if (!tableDatasetId) {
		hide('#searchColumnsList')
		return
	}
	show('#searchColumnsList')
	const searchColumnsContainer = document.querySelector('#searchColumnsList')
	if (searchColumnsContainer) {
		const tableFieldsAndNames =
			getContextData('tableFieldsAndNames')[tableDatasetId] || {}
		let htmlContent = `<span id="searchColumns"><li role="menuitem"><div class="dropdown-item d-flex justify-content-between" role="button" id="toggleSearchColumnSelection" name="toggleSearchColumnSelection"><strong>${gettext(
			'Folgende Spalten durchsuchen'
		)}</strong>&nbsp;<span id="toggleSearchColumnSelectionOpen"><i class="far fa-angle-up fa-1x"></i></span><span id="toggleSearchColumnSelectionOpen" class="hidden"><i class="far fa-angle-down fa-1x"></i></span></span></div></li><span id="searchColumnsContainer">`
		for (const [fieldId, field] of Object.entries(tableFieldsAndNames)) {
			htmlContent += `<li role="menuitem"><div class="dropdown-item searchColumnItem">`
			htmlContent += `<input type="checkbox" class="form-check-input col-auto searchColumn" name="searchColumn-${fieldId}" id="searchColumn-${fieldId}" data-id="${fieldId}" ${
				field.selected ? 'checked' : ''
			}></input>`
			htmlContent += `<label class="form-check-label ps-2" for="searchColumn-${fieldId}">${field.text}</label></div></li>`
		}
		htmlContent += '</span></span>'
		searchColumnsContainer.innerHTML = htmlContent
	}
	eventListenerAdd(
		{
			'#searchColumns': (event) => {
				let target
				if (event.target && event.target.classList.contains('searchColumn'))
					target = event.target
				else if (event.target.classList.contains('searchColumnItem')) {
					target = event.target.querySelector('input[type=checkbox]')
					target.checked = !target.checked
				}
				debounce(() => {
					if (target) saveSearchColumnSelection(target, tableDatasetId)
				}, delay.default)()
			},
			'#toggleSearchColumnSelection': (event) => {
				toggleVisibility(
					'#searchColumnsContainer, #toggleSearchColumnSelectionOpen, #toggleSearchColumnSelectionClose'
				)
			},
		},
		'click'
	)
	eventListenerAdd(
		{
			'#searchDataTable': (event) => {
				if (document.querySelector('#tableSearchDropdown #searchColumnsList'))
					deFocus(event, '#tableSearchDropdown')
			},
		},
		'keyup'
	)
}

/**
 * Handle whatever the API or appAPI requests returned and update the search column field data
 *
 * @param {object} data -- the response data
 * @param {number} successStatus -- the desired status number to indicate success
 * @param {string} tableDatasetId -- The ID of the table ID from the table's dataset
 * @param {Array} selectedSearchColumns -- The search column name strings as a list
 */
function savedColumnsSuccess(
	data,
	successStatus,
	tableDatasetId,
	selectedSearchColumns
) {
	if (data.status === successStatus) {
		if (document.querySelector('#searchDataTable').value) search()
	}
	const searchColumnData = JSON.parse(
		document.querySelector('#tableFieldsAndNames').innerText
	)
	const currentTableColumns = searchColumnData[tableDatasetId]
	for (const fieldId in currentTableColumns) {
		currentTableColumns[fieldId].selected =
			selectedSearchColumns.includes(fieldId)
	}
	document.querySelector('#tableFieldsAndNames').innerText =
		JSON.stringify(searchColumnData)
}

/**
 * Save the selected columns to search in
 *
 * @param {object} target -- The checkbox element that belongs to the column selection
 * @param {string} tableDatasetId -- The ID of the table ID from the table's dataset
 */
function saveSearchColumnSelection(target, tableDatasetId) {
	const checkedColumnCheckboxes = document.querySelectorAll(
		'#searchColumnsList input[type=checkbox]:checked'
	)
	const selectedSearchColumns = []
	if (checkedColumnCheckboxes.length < 1) {
		target.checked = true
		selectedSearchColumns.push(target.dataset.id)
		alertDanger('TBx400xNoSearchColumnsSelected', undefined, {
			shortDescriptionOnly: true,
		})
	} else {
		checkedColumnCheckboxes.forEach((element) => {
			selectedSearchColumns.push(element.dataset.id)
		})
	}

	const selectedColumnsString = selectedSearchColumns.join(',')
	const personalSettingsVariable = getContextData(
		'personalSettingSearchTableVariable'
	)[tableDatasetId]
	const bodyData = {}
	bodyData[personalSettingsVariable] = selectedColumnsString

	let url = `/api/latest/member/${getContextData('mePk')}`
	let successStatus = 200
	const isTaxUser = getContextData('isTaxUser')
	if (isTaxUser) {
		url = `/app/bookkeeping/taxuser/`
		successStatus = 204
	}
	fetch(url, {
		method: 'PATCH',
		headers: headers(),
		body: JSON.stringify(bodyData),
	}).then((data) => {
		savedColumnsSuccess(
			data,
			successStatus,
			tableDatasetId,
			selectedSearchColumns
		)
	})
}

/**
 * Searches the datatable for the value in the search input
 */
function search() {
	const searchElement = document.querySelector('#searchDataTable')
	if (checkForWhitespaceAndRemoveIt(searchElement)) return

	const searchTerm = searchElement.value
	const tableId = searchElement.dataset.targetid || 'usersTable'
	for (const id of tableId.split(',')) {
		$(`#${id}`).DataTable().search(searchTerm).draw()
	}
}

/**
 * Shows or hides the X button in the search input depending on the search value
 *
 * @param {string} searchInputSelector - selector for the search input element
 * @param {string} buttonSelector - selector for the delete button element
 */
export function toggleEmptySearchButton(
	searchInputSelector = '#searchDataTable',
	buttonSelector = '#emptySearch'
) {
	const searchInput = document.querySelector(searchInputSelector)
	const searchParam = searchInput.value
	if (!searchParam.trim()) {
		hide(buttonSelector)
	} else {
		show(buttonSelector)
	}
}

/**
 * Sets up the delete button of the search input
 */
function configureDeleteSearchButton() {
	document.querySelector('#emptySearch')?.addEventListener('click', () => {
		document.querySelector('#searchDataTable').value = ''
		toggleEmptySearchButton()
		search()
	})
}

/**
 * Checks if the search input starts with any whitespace and removes it.
 *
 * @param {Element} searchInput - Search input element
 * @returns {boolean} - if the search input consists of only spaces " "
 */
export function checkForWhitespaceAndRemoveIt(searchInput) {
	let IsOnlySpaces = false
	const regex = /^\s*$/
	if (searchInput.value.length > 0 && regex.test(searchInput.value)) {
		IsOnlySpaces = true
	}
	searchInput.value = searchInput.value.trimStart()

	return IsOnlySpaces
}

/**
 * Loads in the search param if there is one but it isn't shown in the search input
 *
 * @param {object} settings - datatable settings
 */
export function loadSearchParam(settings) {
	const searchInput = document.querySelector('#searchDataTable')
	if (
		!searchInput.value &&
		(settings.oSavedState.search.search || settings.oPreviousSearch.sSearch)
	) {
		searchInput.value =
			settings.oSavedState.search.search || settings.oPreviousSearch.sSearch
		toggleEmptySearchButton()
	}
}

/**
 * Set the targetid dataset attribute of the search input to the id of the current table
 *
 * @param {string} targetId - (optional, use it if you are not using tabs) An string with the id of the table that should be the target of the search field
 */
export function setSearchTargetId(targetId) {
	targetId = targetId || document.querySelector('.tab-pane.active table').id
	document.querySelector('#searchDataTable').dataset.targetid = targetId
}

/**
 * Show the table configuration and disable exports
 */
export function showTableSettings() {
	show('#tableSettings')
	document
		.querySelectorAll('.exportLink')
		.forEach((element) => element.classList.add('disabled'))
}

/**
 * Hide the table configuration and enable exports
 */
export function hideTableSettings() {
	hide('#tableSettings')
	document
		.querySelectorAll('.exportLink')
		.forEach((element) => element.classList.remove('disabled'))
}

/**
 * Save and hide the table configuration
 *
 * @param {Event} event - the click event from the "save" button
 * @param {string} kind - The kind of the table, either 'OrgUser', 'Address' or 'Invoice'
 * @param {Array} fields - the fields for the table
 * @param {number} length - the length of the table
 */
export async function saveTableSettings(event, kind, fields, length = null) {
	const tableFields = fields || getContextData('tableFieldsData')

	if (!length) {
		length = document.querySelector('#dataTablePageLength').value
	}
	const data = {
		kind: kind,
		fields: tableFields,
		sort:
			document.querySelector('#sortDataTableDir')?.value +
			document.querySelector('#sortDataTable')?.value,
		length: length,
	}
	try {
		const response = await fetch('/app/api/updateTableLayout/', {
			method: 'POST',
			body: JSON.stringify(data),
		})
		const json = response.json()
		hideTableSettings()
		buildSearchColumnSelection(event.target.dataset.tableId)
		successMessage(json)
	} catch {
		internalError({})
	}
}

/**
 * force redraw all datatables in document
 */
export function forceDataTableRedraw() {
	$.fn.dataTable.tables().forEach((table) => {
		const tableId = `#${table.id}`
		$(tableId).DataTable().draw()
		initStickyHorizontalTableScrollbar(tableId)
	})
}

/**
 * Makes button groups vertically when the screen is too narrow changed
 */
export function switchBtnGroupVertical() {
	const width = document.body.clientWidth
	if (width < 450) {
		document
			.querySelectorAll('.sticky-actions-bar .btn-group')
			.forEach((element) => {
				element.classList.replace('btn-group', 'btn-group-vertical')
			})
	} else {
		document
			.querySelectorAll('.sticky-actions-bar .btn-group-vertical')
			.forEach((element) => {
				element.classList.replace('btn-group', 'btn-group')
				element.classList.replace('btn-group-vertical', 'btn-group')
			})
	}
}

/**
 * Get the params to add to the url
 *
 * @param {string} selector - the selector of the table
 * @returns {string} the params for the url
 */
export function getTableParamsForUrl(selector = '#usersTable') {
	return $(selector).DataTable()
		? `&${$.param($(selector).DataTable().ajax.params())}`.replace(
				// if the table has a length parameter, remove it
				/&length=\d+/,
				''
		  )
		: ''
}
