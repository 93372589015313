import { triggerExtendedErrorMessage } from './messages'
import { enableLogger } from '../globals/logger'

/**
 * setup shortCuts on all Pages in easyVerein
 */
export default function startShortCuts() {
	const shortCuts = {
		'Control + Shift + S': 'show all ShortCuts in console',
		'Control + e': 'open all extended ErrorMessages on currrent side',
		'Control + Shift + E': 'start recording debug outputs',
	}
	const isKeyPressed = {}

	document.addEventListener('keydown', function (keyDownEvent) {
		isKeyPressed[keyDownEvent.key] = true

		switch (true) {
			case isKeyPressed.Control && isKeyPressed.e:
				// Control + e open all ExtendedErrorMessages on current Side
				document.querySelectorAll('.err-ex-call').forEach((err) => {
					triggerExtendedErrorMessage(err)
				})
				break
			case isKeyPressed.Control && isKeyPressed.Shift && isKeyPressed.S:
				// Show shortCuts in Console
				console.log(shortCuts)
				break
			case isKeyPressed.Control && isKeyPressed.Shift && isKeyPressed.E:
				// Show shortCuts in Console
				enableLogger()
				break
			default:
				break
		}

		if (
			keyDownEvent.altKey &&
			['ArrowDown', 'ArrowUp'].includes(keyDownEvent.key)
		) {
			const navbar = document.getElementById('navbar')
			const navigationElement = navbar.querySelector('.active')
			const treeElement = navbar.querySelector('.treeview .active')
			const element = treeElement || navigationElement
			const currentLocation = element.querySelector('a')

			if (currentLocation) {
				const allLocations = navbar.querySelectorAll('a')
				let nextLocation = null
				let i = 0

				for (const location of allLocations) {
					if (currentLocation === location) {
						nextLocation =
							allLocations[keyDownEvent.key === 'ArrowDown' ? i + 1 : i - 1]
						if (
							nextLocation.href.includes('/public/') ||
							nextLocation.href.includes('/#')
						) {
							nextLocation =
								allLocations[event.key === 'ArrowDown' ? i + 2 : i - 2]
						}
						break
					}
					i += 1
				}
				if (nextLocation) {
					nextLocation.click()
				}
			}
		}
	})

	document.addEventListener('keyup', function (keyUpEvent) {
		isKeyPressed[keyUpEvent.key] = false
	})
}
