import $ from 'jquery'

import 'datatables.net'
import 'jQuery-QueryBuilder'
import 'jquery-ui'
import { Modal } from 'bootstrap'

import { internalError, successMessage } from '../globals/messages'
import { setLanguage } from '../utils/datatables'
import { gettext, interpolate } from '../utils/translation'
import {
	show,
	hide,
	hideElement,
	showElement,
	setVisibility,
} from './helpers/show'
import { getAsyncMassActionData } from './massAction'
import {
	alertAsyncTask,
	alertDangerConfirmation,
	alertSuccess,
} from '../globals/alerts'
import { escapeHtml } from './html'
import { buildPAINValidationResult } from '../app/bookkeeping/bookkeeping/utils'
import { get } from '../api/crud'

/**
 * Retrieves the Celery tasks, updates the task badge and tasks
 */
export function getTasks() {
	$.ajax({
		url: '/app/api/get-tasks/',
	})
		.done(function (data) {
			const tasksBadge = $('#sidebar-toggle span.badge--tasks')
			if (data.total > 0) {
				tasksBadge.show()
				$('#sidebar-toggle span.badge--tasks--wrapper').removeClass('hidden')
				tasksBadge.html(data.total)
			} else {
				tasksBadge.hide()
			}
			if (data && data.tasks) {
				data.tasks.forEach((task) => {
					_updateTasks(task)
				})
			}
		})
		.fail()
}

/**
 * Shows a sweetalert for when the task has started, flashes the task queue button and reloads the tasks.
 * May also directly download a file, if the `Content-Disposition` is `attachment`
 *
 * @param {string} confirmMessage - Message to show in the sweetalert, usually should come from the backend
 * @param {object} response - the response of a request // TODO: I'm not really sure what this really is
 * @param {Function} callback - (optional) A callback function that will be called after confirming
 */
export function confirmTaskStarted(confirmMessage, response, callback) {
	if (
		response &&
		response.headers &&
		response.headers.get('Content-Disposition') !== null &&
		response.headers.get('Content-Disposition').startsWith('attachment;')
	) {
		const filename = response.headers
			.get('Content-Disposition')
			.match(/filename="(.*)"/)[1]
		const blobPromise = response.blob()
		saveTaskAsFile(filename, blobPromise)
	} else {
		alertSuccess(undefined, confirmMessage).then(() => {
			flashQueueButton(2)
			if (callback) callback()
		})
		getTasks()
	}
}

/**
 * Downloads the task file. Bit hacky workaround.
 *
 * @param {string} filename - name of the downloaded file
 * @param {Promise<Blob>} blobPromise - Promise that resolves to `Blob`
 */
function saveTaskAsFile(filename, blobPromise) {
	blobPromise.then((blob) => {
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveBlob(blob, filename)
		} else {
			const elem = window.document.createElement('a')
			elem.href = window.URL.createObjectURL(blob)
			elem.download = filename
			document.body.appendChild(elem)
			elem.click()
			document.body.removeChild(elem)
		}
	})
}

/**
 * Flashes the `#sidebar-toggle` until `flashes` is 0 with a delay of `flashTime`
 *
 * @param {number} flashes - number of flashes
 * @param {number} [flashTime=250] - delay between the flashes in ms [default: 250]
 */
export function flashQueueButton(flashes, flashTime = 250) {
	flashes--
	const queueToggle = document.querySelector('.main-header #sidebar-toggle svg')
	const flashDiv = document.querySelector('#flashDiv')
	queueToggle.classList.add('text-primary')
	flashDiv.classList.add('bg-white')
	setTimeout(() => {
		queueToggle.classList.remove('text-primary')
		flashDiv.classList.remove('bg-white')
	}, flashTime)

	if (flashes > 0) {
		setTimeout(() => flashQueueButton(flashes), flashTime * 2)
	}
}

/**
 * Updates the given task
 *
 * @param {object} taskData - task object instance
 */
function _updateTasks(taskData) {
	_updateTasksInQueue(taskData)
	_updateActiveTaskInHeader(taskData)
	// If the offsetWidth is 0 the element is not visible
	const hasDeletableTasks =
		document.querySelector('.queueAction.queueAction--delete').offsetWidth > 0
	setVisibility('#deleteAllQueueTasks', hasDeletableTasks)
}

/**
 * Updates the UI of the given task
 *
 * @param {object} taskData - task object instance
 */
function _updateTasksInQueue(taskData) {
	// Special cases
	if (
		taskData.details != null &&
		taskData.details.mode === 'ONLINEBANKING_IMPORT'
	) {
		_updateOnlineBankingTask(taskData)
		return
	}

	// Default cases
	const id = taskData.id
	const details = taskData.details
	const queue = document.querySelector('#queues')
	const template = document.querySelector('.queue.template')
	const taskName = details
		? escapeHtml(details.description) || escapeHtml(details.name)
		: ''
	const comment = ` <strong>${escapeHtml(taskData.comment)}</strong>`
	let instance = document.querySelector(`.queue[data-id="${id}"]`)
	if (template == null) {
		console.warn(
			'The task `.queue.template` is missing, make sure it is in the template'
		)
		return
	}
	// Create an instance, if none exists for the current task
	if (instance == null) {
		instance = template.cloneNode(true)
		instance.classList.remove('template')
		instance.dataset.id = id
		instance.querySelector('.queue__name').innerHTML = taskName + comment
		instance
			.querySelectorAll('.queueAction')
			.forEach((button) => hideElement(button))
		hideElement(instance.querySelector('.queueDates__end'))
		queue.insertAdjacentElement('afterbegin', instance)
	}
	instance.querySelector('.queue__name').innerHTML = taskName + comment

	if (taskData.state === 'PROGRESS') {
		_renderTaskInQueueProgressState(instance, id, details, taskData)
	} else if (taskData.state === 'PENDING') {
		_renderTaskInQueuePendingState(instance, id, details, taskData)
	} else {
		_renderTaskInQueueDoneState(instance, id, details, taskData, taskName)
	}
	const downloadButton = instance.querySelector(
		'.queueAction.queueAction--download'
	)
	if (taskData.state === 'FAILURE') {
		instance.querySelector('.queueProgress__bar').classList.add('bg-warning')
		instance.querySelector('.queueProgress__title').classList.add('text-dark')
		_renderTaskInQueueFailureState(downloadButton)
	} else if (taskData.state === 'SOFT-FAILURE') {
		instance.querySelector('.queueProgress__bar').classList.add('bg-warning')
		instance.querySelector('.queueProgress__title').classList.add('text-dark')
		_renderTaskInQueueSoftFailureState(
			downloadButton,
			details,
			taskData,
			taskName
		)
	}

	if ($('#queues').find('span.loading').length) {
		$('.badge--tasks').addClass('loading-shimmer')
		$('#currentlyRunningTaskPreview').addClass('show')
	} else {
		$('.badge--tasks').removeClass('loading-shimmer')
		if ($('#currentlyRunningTaskPreview').hasClass('show')) {
			setTimeout(() => {
				if (
					!document
						.querySelector('#currentlyRunningTaskPreview')
						.classList.contains('loading')
				) {
					$('#currentlyRunningTaskPreview').removeClass('show')
				}
			}, 7500)
		}
	}
}

/**
 * Renders the task if its state is `'PROGRESS'`
 *
 * @param {Node} instance - task DOM node
 * @param {string} id - task id
 * @param {object} details - taskData.details
 * @param {object} taskData - task object instance
 */
function _renderTaskInQueueProgressState(instance, id, details, taskData) {
	setCancelButton(instance, id, details)

	instance.classList.add('loading')
	const text = `${details.name} - ${details.done}/${details.total}`
	instance.querySelector('.queueProgress__title').innerHTML = text
	const percentage = (`${details.done}` / `${details.total}`) * 100
	instance.querySelector('.queueProgress__bar').style.width = `${percentage}%`
	instance.querySelector(
		'.queueDates__startedAt'
	).innerHTML = `${taskData.started}`
	setTimeout(updateTaskProgress, 1000, id)
}

/**
 * Renders the task if its state is `'PENDING'`
 *
 * @param {Node} instance - task DOM node
 * @param {string} id - task id
 * @param {object} details - taskData.details
 * @param {object} taskData - task object instance
 */
function _renderTaskInQueuePendingState(instance, id, details, taskData) {
	setCancelButton(instance, id, details)
	instance.classList.add('loading')
	const text = gettext('Wird gestartet...')
	instance.querySelector('.queueProgress__title').innerHTML = text
	instance.querySelector('.queueProgress__bar').classList.remove('w-0')
	instance.querySelector('.queueProgress__bar').style.width = '0%'
	instance.querySelector(
		'.queueDates__startedAt'
	).innerHTML = `${taskData.started}`
	setTimeout(updateTaskProgress, 1000, id)
}

/**
 * Renders the task if its state is `'DONE'`
 *
 * @param {Node} instance - task DOM node
 * @param {string} id - task id
 * @param {object} details - taskData.details
 * @param {object} taskData - task object instance
 * @param {string} taskName - name of the task (either `details.description` or `details.name`)
 */
function _renderTaskInQueueDoneState(
	instance,
	id,
	details,
	taskData,
	taskName
) {
	removeCancelButton(instance)
	const wasLoading = instance.classList.contains('loading')
	instance.classList.remove('loading')
	let downloadUrl = `/app/api/download-task/${id}`
	if (Object.prototype.hasOwnProperty.call(taskData, 'label')) {
		instance.querySelector(
			'.queueProgress__title'
		).innerHTML = `${taskData.label}`
	} else {
		instance.querySelector('.queueProgress__title').innerHTML = '100%'
	}
	instance.querySelector('.queueProgress__bar').style.width = ''
	instance.querySelector('.queueProgress__bar').classList.add('w-100')
	if (Object.prototype.hasOwnProperty.call(taskData, 'finished')) {
		instance.querySelector(
			'.queueDates__finishedAt'
		).innerHTML = `${taskData.finished}`
		showElement(instance.querySelector('.queueDates__end'))
		hideElement(instance.querySelector('.queueDates__start'))
	} else {
		instance.querySelector(
			'.queueDates__startedAt'
		).innerHTML = `${taskData.started}`
	}
	const downloadButton = instance.querySelector(
		'.queueAction.queueAction--download'
	)
	if (
		details !== null &&
		Object.prototype.hasOwnProperty.call(details, 'mode')
	) {
		switch (details.mode) {
			case 'CREATE_INVOICES':
				_downloadButtonCreateInvoices(downloadButton)
				break
			case 'MASS_EMAILS':
				_downloadButtonMassEmails(downloadButton, details)
				break
			case 'PAINVALIDATION':
				_downloadButtonPainValidation(downloadButton)
				break
			case 'ZIP':
				_downloadButtonZIP(downloadButton, details)
				break
			case 'SERIENBRIEF_CLOUD':
				_downloadButtonSerienbriefCloud(downloadButton)
				break
			case 'MASS_ACTION':
				_downloadButtonMassAction(downloadButton, details)
				break
			case 'DOWNLOAD_AS_PDF':
				_downloadButtonDownloadAsPdf(downloadButton, details)
				break
			case 'CHECK_INTEGRITY':
				_downloadButtonIntegrityCHeck(downloadButton)
				break
			case 'DATEV':
				downloadUrl = _downloadButtonDatev(downloadButton, details)
				break
			case 'FINANCIAL_REPORTS':
				_downloadButtonFinancialReports(downloadButton, details, wasLoading)
				break
			case 'IMPORT_CSV':
				_downloadButtonImportCsv(downloadButton, downloadUrl, wasLoading)
				break
			case 'CREATE_PASSES':
				_downloadButtonCreatePasses(downloadButton, details)
				break
			default:
				break
		}
	}
	instance.querySelector('.queueAction.queueAction--download').href =
		downloadUrl

	_setDeleteButton(instance, id, taskName)

	instance
		.querySelectorAll('.queueAction')
		.forEach((button) => showElement(button))
	removeCancelButton(instance)
}

/**
 * Renders the task if its state is `'FAILURE'`. Hides the download button.
 *
 * @param {Element} downloadButton - DOM element of the download button
 */
function _renderTaskInQueueFailureState(downloadButton) {
	downloadButton.classList.add('queueAction--hidden')
}

/**
 * Renders the task if its state is `'SOFT-FAILURE'`.
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - taskData.details
 * @param {object} taskData - task object instance
 * @param {string} taskName - name of the task (either `details.description` or `details.name`)
 */
function _renderTaskInQueueSoftFailureState(
	downloadButton,
	details,
	taskData,
	taskName
) {
	if (taskData && taskData.details && taskData.details.mode === 'MASS_ACTION') {
		return
	}

	$(downloadButton).html(
		`<i class="far fa-list"></i>&nbsp;${gettext('Details')}`
	)
	downloadButton.classList.replace('btn-primary', 'btn-warning')
	downloadButton.addEventListener('click', function (event) {
		event.preventDefault()
		if (details.mode === 'CREATE_PASSES') {
			alertAsyncTask(undefined, failedPassesMessageData(details), {
				icon: 'error',
				title: gettext('Fehler beim Erstellen von Ausweisen'),
			})
		} else {
			alertAsyncTask(details.error, undefined, {
				messageData: details.messageData,
				icon: 'error',
				title: taskName,
			})
		}
	})
}

/**
 * Renders the members
 *
 * @param {object} details - meta data
 * @returns {string} html for the members
 */
function failedPassesMessageData(details) {
	let html = ''
	const failures = {}
	details.failures.forEach((element) => {
		failures[element[0]] = element[1]
	})
	get('member', '{id,contactDetails{name}}', {
		id__in: Object.keys(failures),
	}).then((response) => {
		response.data.results.forEach((failure) => {
			html += `<div>${failures[failure.id]}: <a href="/app/profile/${
				failure.id
			}#tab_m" class="mb-2">${failure.contactDetails.name}</a><br>`
		})
		document.querySelector('#swal2-html-container').innerHTML += html
	})
	return `<p>${gettext('Folgende Mitglieder sind fehlgeschlagen:')}</p>`
}

/**
 * This button shows a modal for membership fees
 *
 * @param {Element} downloadButton - DOM element of the download button
 */
function _downloadButtonCreateInvoices(downloadButton) {
	downloadButton.addEventListener('click', function (event) {
		event.preventDefault()
		showAsyncMembershipFeeModal(event)
	})
	$(downloadButton).html(
		`<i class="far fa-list"></i>&nbsp;${gettext('Details')}`
	)
}

/**
 * 'Download' (i.e. show) the result of a mass mail action
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - mass action details
 */
function _downloadButtonMassEmails(downloadButton, details) {
	downloadButton.addEventListener('click', function (event) {
		event.preventDefault()
		const total = details.total
		const failedEmails = details.failedEmails
		const failedCount = details.failedCount
		let html = ''
		let icon = ''
		let title = ''

		if (failedCount === total) {
			icon = 'error'
			title = gettext('Es konnten keine E-Mails versendet werden.')
		} else if (failedCount > 0) {
			icon = 'warning'
			title = interpolate(gettext('Es wurden %s von %s E-Mails versendet.'), [
				total - failedCount,
				total,
			])
			html = `<strong>${gettext(
				'Fehlgeschlagene Emails:'
			)}</strong><br>${failedEmails.join('<br>')}`
			if (failedCount > failedEmails.length) {
				html += `<br>${interpolate(gettext('Und %s weitere.'), [
					failedCount - failedEmails.length,
				])}`
			}
			html += '<br><br>'
		} else {
			icon = 'success'
			title = interpolate(gettext('Es wurden %s E-Mails versendet.'), [total])
		}
		html += gettext(
			'Weitere Informationen finden sich im <a href="/app/emaillogs/">E-Mailprotokoll</a>'
		)

		alertAsyncTask(undefined, html, { title, icon })
	})
	$(downloadButton).html(
		`<i class="far fa-list"></i>&nbsp;${gettext('Details')}`
	)
}

/**
 * Pain validation shows the result in a Sweetalert
 *
 * @param {Element} downloadButton - DOM element of the download button
 */
function _downloadButtonPainValidation(downloadButton) {
	downloadButton.addEventListener('click', function (event) {
		event.preventDefault()
		fetch(this.href)
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				const success = data.success
				const html = buildPAINValidationResult(success, data.validation)
				alertAsyncTask(undefined, html, {
					title: success
						? `${gettext('Erfolgreich')}!`
						: `${gettext('Nicht erfolgreich')}!`,
					icon: success ? 'success' : 'error',
				})
			})
	})
}

/**
 * Handle download of ZIp files
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - ZIP meta data
 */
function _downloadButtonZIP(downloadButton, details) {
	downloadButton.addEventListener('click', function (event) {
		event.preventDefault()
		const missingCount = details.content.missingCount
		const title = details.content.title
		const description = details.content.description
		if (missingCount > 0) {
			alertAsyncTask(undefined, description, {
				title: title,
				icon: 'warning',
			}).then(() => {
				window.location = this.href
			})
		} else {
			window.location = this.href
		}
	})
}

/**
 * Handle button for 'Serienbriefe' that have been uploaded to the cloud
 *
 * @param {Element} downloadButton - DOM element of the download button
 */
function _downloadButtonSerienbriefCloud(downloadButton) {
	downloadButton.classList.add('queueAction--hidden')
}

/**
 * Shows the result of an async mass action
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - meta data
 */
function _downloadButtonMassAction(downloadButton, details) {
	downloadButton.addEventListener('click', (e) => {
		getAsyncMassActionData(e, true, details.name)
	})
	$(downloadButton).html(
		`<i class="far fa-list"></i>&nbsp;${gettext('Details')}`
	)
}

/**
 * Wrapps the download of PDFs and checks for errors
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - meta data
 */
function _downloadButtonDownloadAsPdf(downloadButton, details) {
	downloadButton.addEventListener('click', function (event) {
		const errors = details.content.errors
		if (errors?.length) {
			const description =
				gettext(
					'Folgende Dateien konnten nicht in die Datei eingefügt werden:'
				) +
				'<br>' +
				errors.join('<br>')
			alertAsyncTask(undefined, description, {
				title: `${gettext('Warnung')}!`,
				icon: 'warning',
			})
		}
	})
}

/**
 * Shows the result of the integrity check
 *
 * @param {Element} downloadButton - DOM element of the download button
 */
function _downloadButtonIntegrityCHeck(downloadButton) {
	downloadButton.addEventListener('click', (e) => {
		showAsyncIntegrityModal(e)
	})
	downloadButton.innerHTML = `<i class="far fa-list"></i>&nbsp;${gettext(
		'Details'
	)}`
}

/**
 * Sets the correct download URL for datev
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - meta data
 * @returns {string} downloadUrl
 */
function _downloadButtonDatev(downloadButton, details) {
	let downloadUrl = ''
	if (details.content) {
		const content = JSON.parse(details.content)
		downloadUrl = `/app/file/?category=datev&path=${content.data}&use_ev=true`
	} else {
		downloadButton.classList.add('queueAction--hidden')
	}
	return downloadUrl
}

/**
 * Sets the correct download URL for datev
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - meta data
 * @param {boolean} wasLoading - wether the statistic just finished
 */
function _downloadButtonFinancialReports(downloadButton, details, wasLoading) {
	downloadButton.textContent = gettext('Zu der Auswertung')
	if (wasLoading && window.location.href.includes('bookkeeping/statistics')) {
		document
			.querySelector(`#evaluationNav a[href="#${details.criteria}"]`)
			?.dispatchEvent(new Event('click'))
	}
	downloadButton.addEventListener('click', (e) => {
		e.preventDefault()
		if (window.location.href.includes('bookkeeping/statistics')) {
			document
				.querySelector(`#evaluationNav a[href="#${details.criteria}"]`)
				?.dispatchEvent(new Event('click'))
		} else {
			const url = `${window.origin}/app/bookkeeping/statistics/?start=${details.start}&end=${details.end}#${details.criteria}`
			window.location.href = url
		}
	})
}

/**
 * Sets the correct action for CSV import
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {string} downloadUrl - the url
 * @param {boolean} wasLoading - wether the import just finished
 */
function _downloadButtonImportCsv(downloadButton, downloadUrl, wasLoading) {
	downloadButton.textContent = gettext('Ansehen')
	if (wasLoading && window.location.href.includes('app/import/')) {
		setTimeout(() => {
			window.location.href = downloadUrl
		}, 2000)
	}
}

/**
 * Hides the download button for pass creation
 *
 * @param {Element} downloadButton - DOM element of the download button
 * @param {object} details - meta data
 */
function _downloadButtonCreatePasses(downloadButton, details) {
	if (details.failures.length !== 0) {
		downloadButton.innerHTML = `<i class="far fa-list"></i>&nbsp;${gettext(
			'Details'
		)}`
		const title = downloadButton
			.closest('.queue')
			.querySelector('.queueProgress__title')
		title.innerHTML = `${details.failures.length} ${gettext('von')} ${
			details.total
		} ${gettext('fehlgeschlagen')}`
	} else {
		downloadButton.classList.add('invisible')
	}
}

/**
 * Sets cancel button click callback
 *
 * @param {Element} instance - task container
 * @param {number | string} id - id of the task to delete
 * @param {string} taskName - name of the task
 */
function _setDeleteButton(instance, id, taskName) {
	const deleteButton = instance.querySelector(
		'.queueAction.queueAction--delete'
	)
	deleteButton.href = ''

	deleteButton.addEventListener('click', function (e) {
		e.preventDefault()
		alertDangerConfirmation('TKx000xDeleteTask', undefined, {
			messageData: encodeURIComponent(taskName.replaceAll('&quot;', '"')),
		}).then(function (willDelete) {
			if (willDelete.value) {
				_deleteTask(id, instance)
			}
		})
	})
}

/**
 * Updates the header to show the progress in a progressbar
 *
 * @param {object} taskData - task meta data
 */
function _updateActiveTaskInHeader(taskData) {
	const taskId = taskData.id
	const details = taskData.details

	const preview = document.querySelector('#currentlyRunningTaskPreview')
	const currentId = preview.dataset.id

	if (
		currentId === undefined &&
		taskData.state !== 'PROGRESS' &&
		taskData.state !== 'PENDING'
	) {
		return
	}
	if (
		taskId !== currentId &&
		document.querySelectorAll(`.queue.loading[data-id="${currentId}"]`).length >
			0
	) {
		// return because another task is still active
		return
	}
	let text = ''
	let percentage = 100
	let className = 'show '
	hide('#sidebar-toggle .fa-download')

	if (taskData.state === 'PROGRESS') {
		className += 'loading'
		if (
			taskData.details !== null &&
			taskData.details.mode === 'ONLINEBANKING_IMPORT'
		) {
			let finishedAccounts = 0
			for (const account of details.accounts) {
				if (account.importStatus.id === 5 || account.importStatus.id === -1) {
					finishedAccounts++
					continue
				}

				let finishedInPercent = account.importStatus.id * 0.12
				if (account.done || account.total) {
					finishedInPercent +=
						(account.done / account.total) * (1 - finishedInPercent)
				}
				finishedAccounts += finishedInPercent

				if (text === '' || account.importStatus.id === 2) {
					text = account.importStatus.label
				}
				if (account.importStatus.id === 2) {
					text = '<i class="far fa-exclamation"></i> ' + text
					className += ' stalled'
				}
			}
			percentage = (finishedAccounts / details.accounts.length) * 100
		} else {
			text = `${details.name} - ${details.done}/${details.total}`
			percentage = (`${details.done}` / `${details.total}`) * 100
		}
	} else if (taskData.state === 'SUCCESS') {
		text = `<i class="far fa-check"></i> ${taskData.label || '100%'}`
		percentage = 100
		className += 'finished'
		setTimeout(() => {
			document
				.querySelector('#currentlyRunningTaskPreview')
				.classList.remove('show')
		}, 2000)
		setTimeout(() => {
			show('#sidebar-toggle .fa-download')
		}, 3000)
	} else if (
		taskData.state === 'FAILURE' ||
		taskData.state === 'SOFT-FAILURE'
	) {
		text = `<i class="far fa-times"></i> ${
			taskData.label || gettext('Fehlgeschlagen')
		}`
		percentage = 100
		className += 'failure'
	} else {
		className += 'loading'
		percentage = 0
		text = gettext('Wird gestartet...')
	}

	preview.querySelector('.queueProgress__title').innerHTML = text
	preview.querySelector('.queueProgress__bar').style.width = `${percentage}%`
	preview.className = className
	preview.dataset.id = taskId
}

// Stuff from applicationScripts.js

/**
 * Gets the state and progress of a given task by its id and update the progress bar.
 *
 * @param {number | string} taskId - id of the task to retrieve the current progress
 */
function updateTaskProgress(taskId) {
	const url = `/app/api/get-progress/${taskId}`
	$.ajax({ url })
		.done(function (data) {
			_updateTasks(data)
		})
		.fail(function (data) {
			// Do nothing
		})
}

/**
 * TODO: This should probably be just integrated in the function that calls it to reduce code duplication
 *
 * @param {object} taskData - task data from the backend
 */
function _updateOnlineBankingTask(taskData) {
	const id = taskData.id
	const queue = document.querySelector('#queues')
	const details = taskData.details
	const template = document.querySelector('.queue.template-banking')
	const accountProgressTemplate = document.querySelector(
		'.queue.template-banking .accountProgress.template-banking'
	)
	let instance = document.querySelector(`.queue[data-id="${id}"]`)

	if (instance === null) {
		instance = template.cloneNode(true)
		instance.classList.remove('template-banking')
		instance.dataset.id = id
		instance.querySelector('.queue__name').innerHTML = details.description
		instance
			.querySelectorAll('.queueAction')
			.forEach((button) => hideElement(button))
		hideElement(instance.querySelector('.queueDates__end'))

		// This is inperformant, TODO improve the performance
		for (const account of details.accounts) {
			// add a progress bar for each account
			const accountId = account.id
			const accountInstance = accountProgressTemplate.cloneNode(true)
			accountInstance.querySelector('.accountProgress__accountName').innerHTML =
				account.bankAccountName
			accountInstance.dataset.id = accountId
			accountInstance.classList.remove('template-banking')
			instance
				.querySelector('.accountList')
				.insertAdjacentElement('beforeend', accountInstance)
		}
		queue.insertAdjacentElement('afterbegin', instance)
	}
	let waitingForAuthentication = false
	if (taskData.state === 'PROGRESS' || taskData.state === 'SUCCESS') {
		for (const account of details.accounts) {
			const accountId = account.id
			const accountInstance = instance.querySelector(
				`.accountProgress[data-id="${accountId}"]`
			)
			accountInstance.querySelector('.queueProgress__title').innerHTML =
				account.importStatus.label

			let percent
			switch (account.importStatus.id) {
				case -1:
					percent = 100
					break // FAILED
				case 0:
					percent = 0
					break // WAITING_FOR_UPDATE
				case 1: // UPDATING
				case 2:
					percent = 10
					break // CHALLENGE_REQUIRED
				case 3:
					percent = 20
					break // UPDATE_FINISHED
				case 4:
					percent = 30
					break // IMPORTING
				case 5:
					percent = 100
					break // FINISHED
			}
			// TODO inperformant
			if (account.done || account.total) {
				percent += ((100 - percent) * account.done) / account.total
				accountInstance.querySelector(
					'.queueProgress__title'
				).innerHTML += `: ${account.done} / ${account.total}`
			} else if (
				account.importStatus.id === 5 &&
				account.done === 0 &&
				account.total === 0
			) {
				accountInstance.querySelector('.queueProgress__title').innerHTML +=
					': ' + gettext('Keine neuen Transaktionen')
			}
			accountInstance.querySelector(
				'.queueProgress__bar'
			).style.width = `${percent}%`

			if (account.challenge && !account.challenge.finished) {
				waitingForAuthentication = true
				const challengeButton =
					accountInstance.querySelector('.challengeButton')
				challengeButton.innerHTML = gettext('Authentifizieren')
				challengeButton.style.display = 'inline-block'
				challengeButton.href = account.challenge.location
				$(challengeButton).off('click')
				accountInstance.classList.add('stalled')
			} else if (
				account.importStatus.id === -1 &&
				account.importStatus.error !== undefined
			) {
				accountInstance.classList.add('stalled')
				const challengeButton =
					accountInstance.querySelector('.challengeButton')
				challengeButton.style.display = 'inline-block'
				challengeButton.innerHTML = gettext('Fehlerdetails')
				challengeButton.classList.remove('btn-primary')
				challengeButton.classList.add('btn-warning')
				accountInstance
					.querySelector('.queueProgress__bar')
					.classList.add('bg-warning')
				accountInstance
					.querySelector('.queueProgress__title')
					.classList.add('text-dark')
				$(challengeButton)
					.off('click')
					.on('click', function (event) {
						event.preventDefault()
						alertAsyncTask(account.importStatus.error.errorCode, undefined, {
							messageData: account.importStatus.error.extraData || '',
							title: account.bankAccountName,
							icon: 'error',
						})
					})
			}
		}

		instance.querySelector(
			'.queueDates__startedAt'
		).innerHTML = `${taskData.started}`
		if (taskData.state === 'PROGRESS') {
			instance.classList.add('loading')
			setTimeout(updateTaskProgress, 1000, id)
		} else if (
			instance.classList.contains('loading') &&
			window.location.pathname.startsWith('/app/bookkeeping/')
		) {
			const filterBtn = document.querySelector('#addFilterButton')
			if (filterBtn) {
				filterBtn.dispatchEvent(new Event('click'))
				alertSuccess('IEx200xBookingWereReloaded')
			}
		}
	}
	if (taskData.state !== 'PROGRESS' || waitingForAuthentication) {
		const deleteButton = instance.querySelector(
			'.queueAction.queueAction--delete'
		)
		deleteButton.href = ''
		showElement(deleteButton)
		deleteButton.addEventListener('click', function (e) {
			e.preventDefault()
			_deleteTask(id, instance)
		})

		if (taskData.state !== 'PROGRESS') {
			instance.classList.remove('loading')
		}

		if (taskData.state === 'FAILURE' || taskData.state === 'SOFT-FAILURE') {
			instance.querySelectorAll('.queueProgress__title').forEach((value) => {
				value.textContent = gettext('Fehlgeschlagen')
			})
			instance.querySelector('.queueProgress__title').classList.add('text-dark')
			instance.querySelector('.queueProgress__bar').classList.add('bg-warning')
		}
	}

	if ($('#queues').find('li.loading').length) {
		$('.badge--tasks').addClass('loading-shimmer')
		$('#currentlyRunningTaskPreview').addClass('show')
	} else {
		$('.badge--tasks').removeClass('loading-shimmer')
		if ($('#currentlyRunningTaskPreview').hasClass('show')) {
			setTimeout(() => {
				if ($('#queues').find('li.loading').length === 0) {
					$('#currentlyRunningTaskPreview').removeClass('show')
				}
			}, 2000)
		}
	}
}

/**
 *  Set the cancel button to make it possible to cancel a ongoing task
 *
 * @param {Element} instance - task container
 * @param {number | string} id - task id
 * @param {object} details - task meta data
 */
function setCancelButton(instance, id, details) {
	if (Boolean(details) && details.mode === 'CREATE_INVOICES') {
		return
	}
	const cancelButton = instance.querySelector(
		'.queueAction.queueAction--cancel'
	)
	if (cancelButton.classList.contains('hidden')) {
		cancelButton.href = ''
		showElement(cancelButton)
		cancelButton.addEventListener('click', (event) => {
			event.preventDefault()
			_cancelTask(id, instance)
		})
	}
}

/**
 * Hide the cancel button
 *
 * @param {Element} instance - task container
 */
function removeCancelButton(instance) {
	const cancelButton = instance.querySelector(
		'.queueAction.queueAction--cancel'
	)
	if (cancelButton) {
		hideElement(cancelButton)
	}
}

/**
 * Cancels the task with the given ID and removes the container from the list
 *
 * @param {number | string} id - task id
 * @param {Element} instance - task container
 */
function _cancelTask(id, instance) {
	const removeUrl = `/app/api/cancel-task/${id}`
	$.ajax({
		url: removeUrl,
	})
		.done(function (data) {
			if (data.success) {
				_removeTaskFromQueue(instance)
				$('#currentlyRunningTaskPreview').removeClass('show')
				$('.badge.badge--tasks.loading-shimmer').removeClass('loading-shimmer')
			} else {
				successMessage(data)
			}
		})
		.fail(function (data) {
			internalError(data)
		})
}

/**
 * Deletes the task with the given ID and removes the container from the list
 *
 * @param {number | string} id - task id
 * @param {Element} instance - task container
 */
function _deleteTask(id, instance) {
	const removeUrl = `/app/api/delete-task/${id}`
	$.ajax({
		url: removeUrl,
	})
		.done(function () {
			_removeTaskFromQueue(instance)
		})
		.fail(function (data) {
			internalError(data)
		})
}

/**
 * Removes all tasks from the queue
 */
function _deleteAllTasks() {
	const deletableTasks = $('.queueAction.queueAction--delete:visible').closest(
		'.queue'
	)
	deletableTasks.each((i, instance) => {
		const id = instance.dataset.id
		_deleteTask(id, instance)
	})
	$('#deleteAllQueueTasks').hide()
}

/**
 * Removes one task from the queue
 *
 * @param {Element} instance - task container
 */
function _removeTaskFromQueue(instance) {
	instance.remove()
	const tasksBadge = $('#sidebar-toggle span.badge--tasks')

	if (tasksBadge) {
		const tasksStr = tasksBadge.text()
		if (tasksStr !== '') {
			let tasksInt = parseInt(tasksStr, 10)
			tasksInt -= 1
			tasksBadge.text(tasksInt)
			if (tasksInt <= 0) {
				$(tasksBadge).hide()
				tasksBadge.text(tasksInt)
			}
		}
	}
}

$('#deleteAllQueueTasks').click(() => {
	alertDangerConfirmation('TKx000xDeleteAllTasks', undefined, {
		confirmButtonText: gettext('Alle löschen'),
	}).then(function (willDelete) {
		if (willDelete.value) {
			_deleteAllTasks()
		}
	})
})

/**
 * Special function that handles the `'CREATE_INVOICES'` in `_updateTasksInQueue`
 *
 * @param {Event} event - click event
 */
async function showAsyncMembershipFeeModal(event) {
	const url = event.currentTarget.href
	const page = $(event.currentTarget).attr('data-page') || 1

	let pageUrl = url
	if (url.indexOf('?') !== -1) {
		pageUrl += '&'
	} else {
		pageUrl += '?'
	}
	pageUrl += `page=${page}`

	const response = await fetch(pageUrl)
	const data = await response.json()

	document.getElementById('membershipFeeModal_title').innerHTML = data.title
	document.getElementById('membershipFeeModal_headline').innerHTML =
		data.headline

	const details = $('<div></div>', { class: 'row' })
	for (const user of data.data) {
		details.append($('<div></div>', { class: 'col-md-4', text: user }))
	}

	if (data.successfulInvoices > 0) {
		show('#membershipFeeModal_detailsButton')
	} else {
		hide('#membershipFeeModal_detailsButton')
	}

	const invoicesList = document.getElementById('invoiceslist')
	if (data.page === 1) {
		document
			.getElementById('asyncMembershipFeeModalInvoicesDetails')
			.classList.remove('in')
		invoicesList.innerHTML = ''
	}

	$('#invoiceslist').append(details)

	// We need to remove all the event listener before adding a new event listener
	// So this is the "easiest" way to do that
	let loadMore = document.getElementById('membershipFeeModal_loadMore')
	let successButton = document.getElementById(
		'membershipFeeModal_successButton'
	)
	for (const element of [loadMore, successButton]) {
		const clonedElement = element.cloneNode(true)
		element.parentNode.replaceChild(clonedElement, element)
	}

	// After replacing the element we also need to retriev it again
	loadMore = document.getElementById('membershipFeeModal_loadMore')
	successButton = document.getElementById('membershipFeeModal_successButton')

	if (data.next_page) {
		show('#membershipFeeModal_loadMore')
		loadMore.setAttribute('href', url)
		loadMore.setAttribute('data-page', data.next_page)
	} else {
		hide('#membershipFeeModal_loadMore')
	}

	document.getElementById('membershipFeeModal_cancelButton').innerHTML =
		data.button

	if (data.dryRun) {
		show('#membershipFeeModal_successButton')
	} else {
		hide('#membershipFeeModal_successButton')
	}

	successButton.disabled = data.successfulInvoices === 0

	const modalElement = document.getElementById('asyncMembershipFeeModal')
	const modal = Modal.getOrCreateInstance(modalElement)

	loadMore.addEventListener('click', (e) => {
		e.preventDefault()
		showAsyncMembershipFeeModal(e)
	})
	if (data.dryRun && data.successfulInvoices > 0) {
		successButton.addEventListener('click', function memberShipFeeListener() {
			membershipFeeRequest(data, modal)
		})
	}

	modal.show()
}

/**
 * helper for eventListeners in showAsyncMembershipFeeModal
 *
 * @param {JSON} data retrieved async task data (dictionary)
 * @param {string} modal modal id
 */
function membershipFeeRequest(data, modal) {
	$.ajax({
		method: 'GET',
		url: '/app/api/runmembershipfee/',
		data: {
			dryRun: false,
			createForDate: data.createForDate,
			recreateCanceledInvoices: data.recreateCanceledInvoices,
			userGroups: JSON.stringify(data.userGroups),
			automaticMembershipFeeFilter: data.automaticMembershipFeeFilter,
		},
	}).done((response) => {
		if (response.success === true) {
			confirmTaskStarted(
				gettext('Die Rechnungserstellung wurde erfolgreich gestartet.'),
				response
			)
			modal.hide()
		} else {
			successMessage(response)
		}
	})
}

/**
 * open integrityCheck Modal and fill modal-error-table
 *
 * @param {event} e onclick event
 */
function showAsyncIntegrityModal(e) {
	e.preventDefault()

	const table = $('#integrityErrorTable')
	const body = document.getElementById('integrityErrorBody')

	if (table) {
		try {
			table.DataTable().destroy()
			body.innerHTML = ''
		} catch {}
	}

	const url = e.currentTarget.href.replace(
		'download-task',
		'integritytabledata'
	)

	table.DataTable(
		setLanguage({
			ajax: { url },
			autoWidth: true,
			columns: [
				{ orderable: true, searchable: true },
				{ orderable: true, searchable: true },
			],
			deferRender: true,
			lengthChange: false,
			ordering: true,
			paging: true,
			dom: 'lrt<"m-0 row"<"col-6"p><"col-6"<"text-end mx-4"i>>>',
			info: true,
			// Hardcoded to match paginator length
			pageLength: 30,
			processing: true,
			scrollX: false,
			searching: true,
			serverSide: true,
		})
	)

	const modalElement = document.getElementById('appIntegrityModal')
	const modal = Modal.getOrCreateInstance(modalElement)
	modal.show()
}
