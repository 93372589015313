import { getContextData } from '../utils/django'
import { alertWarning } from './alerts'

/**
 * setup session activity checker on window focus
 */
export default function startCheckSessionActivity() {
	window.addEventListener('focus', function () {
		checkSessionActivity()
	})
}

/**
 * Checks if the user is still logged in and shows a Swal.
 */
async function checkSessionActivity() {
	const userId = getContextData('user_id')
	const isEmptyArray = Array.isArray(userId) && userId.length === 0
	if (!userId || isEmptyArray) {
		return
	}
	let data
	try {
		const response = await fetch(`/app/api/checksessionactivity/${userId}`)
		data = await response.text()
	} catch (error) {
		data = 'ok'
	}
	if (data === 'changed' || data === 'inactive') {
		document.querySelector('body').classList.add('swal2-blur')
		alertWarning('SEx000xSessionExpired').then(() => {
			window.location = '/app/'
		})
	}
}
