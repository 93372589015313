import $ from 'jquery'
import { escapeHtml } from '../utils/html'
import { changeValueFormat, showFilterActivationNotice } from './customFilters'
import { getContextData } from './django'
import { gettext, interpolate } from './translation'
import createQueryBuilder, { addToFilter } from './queryBuilder'
import { getModelsFromApi } from '../utils/getModelsFromApi'
import { onclickElements } from './helpers/eventHelpers'
import { buildGroups } from '../../js/utils/groupUtilities.js'

// Base options for the filter operators.
export const FILTER_OP_BOOL = ['equal']
export const FILTER_OP_DATE = [
	'equal',
	'not_equal',
	'less',
	'greater',
	'less_or_equal',
	'greater_or_equal',
	'is_null',
	'is_not_null',
]
export const FILTER_OP_DEFAULT = ['is_null']
export const FILTER_OP_DOUBLE = [
	'equal',
	'less',
	'greater',
	'less_or_equal',
	'greater_or_equal',
	'is_null',
	'is_not_null',
]
export const FILTER_OP_DOUBLE_NOT_NULL = [
	'equal',
	'less',
	'greater',
	'less_or_equal',
	'greater_or_equal',
]
export const FILTER_OP_FILE = ['is_null', 'is_not_null']
export const FILTER_OP_INTEGER = [
	'equal',
	'not_equal',
	'is_null',
	'is_not_null',
]
export const FILTER_OP_INTEGER_NOT_NULL = ['equal', 'not_equal']
export const FILTER_OP_INTEGER_JUST_EQUAL = ['equal']
export const FILTER_OP_STRING_ONLY_EQUAL = ['equal', 'not_equal']
export const FILTER_OP_MULIT_VALUE = ['is_null', 'is_not_null']
export const FILTER_OP_TEXT = [
	'equal',
	'not_equal',
	'contains',
	'not_contains',
	'is_empty',
	'is_not_empty',
	'is_null',
	'is_not_null',
	'begins_with',
	'less_or_equal',
	'greater_or_equal',
]
export const FILTER_OP_TEXT_SELECT = [...FILTER_OP_TEXT]

export const FILTER_INPUT_TYPE = {
	DEFAULT: 'text',
	// There is no input type for file needed.
	FILE: null,
	SELECT: bootstrap5SelectionBoxInputType,
	TEXT: 'text',
	SINGLE_SELECT: customSingleSelectBoxInputType,
	MULTI_SELECT: customMultiSelectBoxInputType,
}

export const FILTER_PLUGN_TYPE = {
	DEFAULT: 'chosen',
	CHOSEN: 'chosen',
}

export const FILTER_TYPE = {
	BOOL: 'boolean',
	DATE: 'date',
	DATE_RANGE: 'datetime',
	DOUBLE: 'double',
	DOUBLE_NOT_NULL: 'double_not_null',
	// There is no data type for file needed.
	FILE: null,
	INTEGER: 'integer',
	INTEGER_NOT_NULL: 'integer_not_null',
	INTEGER_JUST_EQUAL: 'integer_just_equal',
	STRING: 'string',
	STRING_ONLY_EQUAL: 'string_only_equal',
	MULTI_VALUE: null,
}

/**
 * Creates the filters used for the query builder. Changes `filters` inplace
 *
 * @param {Array} filters - The created filter will be pushed to this array.
 * @param {Array} filterGroup - A array of data used for creating a filter object.
 * Order for the filter group array is: id, input (optional), label, placeholder (optional), type, values (optional), plugin (optional), plugin_config (needed for plugin otherwise optional), valueSetter (optional).
 * @param {string} groupName - The group name. Used as seperator / description.
 */
export function createFilter(filters, filterGroup, groupName = null) {
	if (filters === undefined || filters == null || filters.count === 0) {
		return
	}
	if (
		filterGroup === undefined ||
		filterGroup == null ||
		filterGroup.count === 0
	) {
		return
	}
	filterGroup.sort((a, b) => a[2].localeCompare(b[2]))

	for (const i in filterGroup) {
		const fg = filterGroup[i]
		const pluginConfig = fg[7] !== (undefined || null) ? fg[7] : null
		const filterType = fg[4]
		const inputTypeFunction = fg[1]
		let type
		switch (filterType) {
			case FILTER_TYPE.INTEGER_NOT_NULL:
			case FILTER_TYPE.INTEGER_JUST_EQUAL:
				type = FILTER_TYPE.INTEGER
				break
			case FILTER_TYPE.DOUBLE_NOT_NULL:
				type = FILTER_TYPE.DOUBLE
				break
			case FILTER_TYPE.STRING_ONLY_EQUAL:
				type = FILTER_TYPE.STRING
				break
			default:
				type = filterType
		}
		// the vanillajs-datepicker fires `changeDate` instead of a simple `change` event
		const inputEvent = getInputEvent(type, inputTypeFunction)
		filters.push({
			id: fg[0],
			// Can be set manually, if needed.
			input:
				inputTypeFunction !== null
					? inputTypeFunction
					: getFilterInputType(fg[4]),
			label: fg[2],
			operators: getFilterOperator(filterType),
			optgroup: groupName,
			placeholder: fg[3],
			// There is no 'not null integer' type, so after assigning the operator it will use the normal integer.
			type: type,
			values: fg[5] !== (undefined || null) ? fg[5] : null,
			plugin: fg[6] !== (undefined || null) ? fg[6] : null,
			pluginConfig: pluginConfig,
			multiple: pluginConfig?.multiple ?? false,
			valueSetter: fg[8] !== (undefined || null) ? fg[8] : null,
			valueGetter: fg[9] !== (undefined || null) ? fg[9] : null,
			input_event: inputEvent,
		})
	}
}

/**
 * Get the input event type as a string
 *
 * @param {string} type - The string representative of a values type (like 'boolean')
 * @param {Function} inputType - The function returning the html string needed for this type of input
 * @returns {string} A string of the event listening to to detect a changed value
 */
function getInputEvent(type, inputType) {
	if (type === FILTER_TYPE.DATE || type === FILTER_TYPE.DATE_RANGE) {
		return 'changeDate'
	} else if (inputType === FILTER_INPUT_TYPE.SINGLE_SELECT) {
		return 'selected'
	}
	return 'change'
}

/**
 * Get a input type depending on the data type.
 *
 * @param {string} typeName -  filter type, see `FILTER_TYPE`
 * @returns {string} `FILTER_INPUT_TYPE` value
 */
export function getFilterInputType(typeName) {
	switch (typeName) {
		case FILTER_TYPE.BOOL:
		case FILTER_TYPE.INTEGER:
		case FILTER_TYPE.INTEGER_NOT_NULL:
		case FILTER_TYPE.INTEGER_JUST_EQUAL:
		case FILTER_TYPE.MULTI_VALUE:
			return FILTER_INPUT_TYPE.SELECT
		case FILTER_TYPE.DATE:
		case FILTER_TYPE.DOUBLE:
		case FILTER_TYPE.DOUBLE_NOT_NULL:
		case FILTER_TYPE.STRING_ONLY_EQUAL:
		case FILTER_TYPE.STRING:
			return FILTER_INPUT_TYPE.TEXT

		case FILTER_TYPE.FILE:
			return FILTER_INPUT_TYPE.FILE

		default:
			return FILTER_INPUT_TYPE.DEFAULT
	}
}

/**
 * Get the filter operators depending on the data type.
 *
 * @param {string} typeName -  filter type, see `FILTER_TYPE`
 * @returns {Array<string>} operator list
 */
export function getFilterOperator(typeName) {
	switch (typeName) {
		case FILTER_TYPE.BOOL:
			return FILTER_OP_BOOL
		case FILTER_TYPE.DATE_RANGE:
		case FILTER_TYPE.DATE:
			return FILTER_OP_DATE
		case FILTER_TYPE.DOUBLE:
			return FILTER_OP_DOUBLE
		case FILTER_TYPE.DOUBLE_NOT_NULL:
			return FILTER_OP_DOUBLE_NOT_NULL
		case FILTER_TYPE.FILE:
			return FILTER_OP_FILE
		case FILTER_TYPE.INTEGER:
			return FILTER_OP_INTEGER
		case FILTER_TYPE.INTEGER_NOT_NULL:
			return FILTER_OP_INTEGER_NOT_NULL
		case FILTER_TYPE.INTEGER_JUST_EQUAL:
			return FILTER_OP_INTEGER_JUST_EQUAL
		case FILTER_TYPE.STRING_ONLY_EQUAL:
			return FILTER_OP_STRING_ONLY_EQUAL
		case FILTER_TYPE.STRING:
			return FILTER_OP_TEXT
		case FILTER_TYPE.STRING_SELECT:
			return FILTER_OP_TEXT_SELECT
		case FILTER_TYPE.MULTI_VALUE:
			return FILTER_OP_MULIT_VALUE

		default:
			return FILTER_OP_DEFAULT
	}
}

/**
 * Creates the filters for the query builder
 *
 * @param {Array} groupData - The results of the `member-group` endpoint, it should look like this: [{'id': $id, 'name': $name}]
 */
export function buildMemberFilters(groupData) {
	const groups = {}
	document
		.querySelectorAll('select[name=group],select[name=cGroup]')
		.forEach((select) => {
			select.innerHTML = ''
		})
	let groupsFilterHTML = ''
	for (const data of groupData) {
		groups[data.id] = escapeHtml(data.name)
		groupsFilterHTML += `<li class="dropdownOption"><a data-id="${
			data.id
		}" class="fw-bold"><i class="far fa-fw fa-user-tag"></i> ${interpolate(
			gettext('Mitglieder in %(groupName)s'),
			{ groupName: escapeHtml(data.name) }
		)}</a></li>`
	}
	if (document.querySelector('#groupsFilter')) {
		document.querySelector('#groupsFilter').innerHTML = groupsFilterHTML
		onclickElements({
			'#groupsFilter a': (event) =>
				addToFilter([
					[
						'usergroupassignment',
						'integer',
						'equal',
						event.currentTarget.dataset.id,
						true,
					],
				]),
		})
	}

	const dosbSports = getContextData('dosbSportsData')
	const lsbSports = getContextData('lsbSportsData')
	const enablePartialInvoicingForAutomatedGroupChanges = getContextData(
		'enablePartialInvoicingForAutomatedGroupChanges'
	)

	// TODO: Fix choosen
	// $('.chosen-select').trigger('chosen:updated')

	const orgShowPrivateContactData = getContextData('orgShowPrivateContactData')
	const orgShowCompanyContactData = getContextData('orgShowCompanyContactData')

	// Id, input type (if null: is depending on data type), label, placeholder (optional), data type, values for selects (optional), plugin type (optional), plugin config (optional), value setter (optional)
	const contactDataTabFilter = [
		[
			'contactDetails__salutation',
			null,
			gettext('Anrede'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__nameAffix',
			null,
			gettext('Titel'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__firstName',
			null,
			gettext('Vorname'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__familyName',
			null,
			gettext('Nachname'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__dateOfBirth',
			null,
			gettext('Geburtstag'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
	]
	if (orgShowPrivateContactData) {
		contactDataTabFilter.push(
			[
				'contactDetails__street',
				null,
				gettext('Straße & Hausnummer'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__zip',
				null,
				gettext('Postleitzahl'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__city',
				null,
				gettext('Stadt'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__country',
				null,
				gettext('Land'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__state',
				null,
				gettext('Bundesland'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__privatePhone',
				null,
				gettext('Telefonnummer (priv.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__mobilePhone',
				null,
				gettext('Handynummer'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__privateEmail',
				null,
				gettext('E-Mail (priv.)'),
				null,
				FILTER_TYPE.STRING,
			]
		)
	}

	if (orgShowCompanyContactData) {
		contactDataTabFilter.push(
			[
				'contactDetails__companyName',
				null,
				gettext('Firmenname'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__professionalRole',
				null,
				gettext('Position'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__companyStreet',
				null,
				gettext('Straße & Hausnummer (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__companyZip',
				null,
				gettext('Postleitzahl (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__companyCity',
				null,
				gettext('Stadt (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__companyCountry',
				null,
				gettext('Land (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__companyState',
				null,
				gettext('Bundesland (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__companyPhone',
				null,
				gettext('Telefonnummer (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails__companyEmail',
				null,
				gettext('E-Mail (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'contactDetails___isCompany',
				null,
				gettext('Adresstyp'),
				null,
				FILTER_TYPE.INTEGER_NOT_NULL,
				{ 1: gettext('Firma'), 0: gettext('Person') },
			]
		)
	}
	contactDataTabFilter.push(
		[
			'contactDetails__preferredCommunicationWay',
			null,
			gettext('Bevorzugte Kommunikationsform'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			{
				0: gettext('E-Mail'),
				1: gettext('Brief'),
				2: gettext('Keine Kommunikation'),
			},
		],
		[
			'contactDetails__invoiceCompany',
			null,
			gettext('Rechnungen an Firma stellen'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Ja'), 0: gettext('Nein') },
		],
		[
			'contactDetails__addressCompany',
			null,
			gettext('Briefe an Firma adressieren'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Ja'), 0: gettext('Nein') },
		],
		[
			'contactDetails__dateOfBirth__month',
			null,
			gettext('Geburtsmonat'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			{
				1: gettext('Januar'),
				2: gettext('Februar'),
				3: gettext('März'),
				4: gettext('April'),
				5: gettext('Mai'),
				6: gettext('Juni'),
				7: gettext('Juli'),
				8: gettext('August'),
				9: gettext('September'),
				10: gettext('Oktober'),
				11: gettext('November'),
				12: gettext('Dezember'),
			},
		],
		[
			'contactDetails__dateOfBirth__year',
			null,
			gettext('Geburtsjahr'),
			null,
			FILTER_TYPE.DOUBLE,
		],
		['age', null, gettext('Alter'), null, FILTER_TYPE.DOUBLE]
	)

	if (enablePartialInvoicingForAutomatedGroupChanges) {
		contactDataTabFilter.push([
			'usergroupassignment__start',
			null,
			gettext('Mitgliedschaftsgruppe (Startdatum)'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		])
		contactDataTabFilter.push([
			'usergroupassignment__end',
			null,
			gettext('Mitgliedschaftsgruppe (Enddatum)'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		])
	}

	const membershipTabFilter = [
		['email', null, gettext('Benutzername'), null, FILTER_TYPE.STRING],
		[
			'membershipNumber',
			null,
			gettext('Mitgliedsnummer'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'joinDate__date',
			null,
			gettext('Beitrittsdatum'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
		[
			'_applicationWasAcceptedAt',
			null,
			gettext('Mitgliedschaftsantrag bestätigt am'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
		[
			'resignationDate',
			null,
			gettext('Austrittsdatum'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
		[
			'resignationNoticeDate',
			null,
			gettext('Kündigungsdatum'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
		[
			'_applicationDate',
			null,
			gettext('Datum der Antragsstellung'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
		[
			'usergroupassignment',
			FILTER_INPUT_TYPE.SINGLE_SELECT,
			gettext('Mitgliedschaftsgruppe'),
			null,
			FILTER_TYPE.INTEGER,
			groups,
		],
		[
			'usergroupassignment__paymentActive',
			FILTER_INPUT_TYPE.SINGLE_SELECT,
			gettext('Mitgliedsgruppe (aktiver Beitrag)'),
			null,
			FILTER_TYPE.INTEGER,
			groups,
		],
		[
			'active',
			null,
			gettext('Mitgliedschaftsstatus'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			{
				0: gettext('Ehemalige Mitgliedschaft'),
				1: gettext('Laufende Mitgliedschaft'),
			},
		],
		[
			'declarationOfApplication',
			null,
			gettext('Mitgliedschaftsantrag'),
			null,
			FILTER_TYPE.FILE,
		],
		[
			'declarationOfResignation',
			null,
			gettext('Austrittserklärung'),
			null,
			FILTER_TYPE.FILE,
		],
		[
			'declarationOfConsent',
			null,
			gettext('Einwilligungserklärung'),
			null,
			FILTER_TYPE.FILE,
		],
		[
			'anniversary',
			null,
			gettext('Mitgliedschaft (in Jahren)'),
			null,
			FILTER_TYPE.DOUBLE,
		],
		[
			'_isBlocked',
			null,
			gettext('Zugriff zum Login verweigert'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Ja'), 0: gettext('Nein') },
		],
		[
			'_isChairman',
			null,
			gettext('Ist Administrator'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Ja'), 0: gettext('Nein') },
		],
		[
			'_isApplication',
			null,
			gettext('Offener Mitgliedschaftsantrag'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Ja'), 0: gettext('Nein') },
		],
		[
			'hasOpenCustomFieldRequests',
			null,
			gettext('Offene Datenänderungen'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Nein'), 0: gettext('Ja') },
		],
		[
			'last_login',
			null,
			gettext('Letzter Login'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
	]

	if (getContextData('unidyEnabled')) {
		membershipTabFilter.push([
			'_oidcSub',
			null,
			gettext('Verbindung mit Unidy'),
			null,
			FILTER_TYPE.FILE,
		])
	}

	const orgChildData = getContextData('orgChildData')
	const isEmpty = (obj) => Object.keys(obj).length === 0
	if (!isEmpty(orgChildData)) {
		membershipTabFilter.push([
			'contactDetails__copiedInstances__org_id',
			null,
			gettext('Abteilung'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			orgChildData,
		])
	}

	const paymentmethods = getContextData('paymentmethods')
	const paymentIntervals = {
		'-1': gettext('Einmalig'),
		1: gettext('Jeden Monat'),
		2: gettext('Alle 2 Monate'),
		3: gettext('Alle 3 Monate'),
		4: gettext('Alle 4 Monate'),
		5: gettext('Alle 5 Monate'),
		6: gettext('Alle 6 Monate'),
		7: gettext('Alle 7 Monate'),
		8: gettext('Alle 8 Monate'),
		9: gettext('Alle 9 Monate'),
		10: gettext('Alle 10 Monate'),
		11: gettext('Alle 11 Monate'),
		12: gettext('Alle 12 Monate'),
		24: gettext('Alle 24 Monate'),
		36: gettext('Alle 36 Monate'),
	}

	const callStates = getContextData('callStates')
	const callStateData = {}
	for (const cs of callStates) {
		callStateData[cs.position] = cs.name
	}
	const membersipFeeTabFilter = [
		[
			'_paymentStartDate',
			null,
			gettext('Erster Leistungszeitraum'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
		[
			'paymentAmount',
			null,
			gettext('Mitgliedschaftsbeitrag'),
			null,
			FILTER_TYPE.DOUBLE,
		],
		[
			'usergroupassignment__groupObject__usergroup__paymentAmount',
			null,
			gettext('Mitgliedschaftsbeitrag (Mitgliedschaftsgruppe)'),
			null,
			FILTER_TYPE.DOUBLE,
		],
		[
			'contactDetails__sepaDate',
			null,
			gettext('Mandatsdatum'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
		[
			'sepaMandateFile',
			null,
			gettext('SEPA-Mandat hochgeladen'),
			null,
			FILTER_TYPE.FILE,
		],
		[
			'paymentIntervallMonths',
			null,
			gettext('Zahlungsintervall (Individuell)'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			paymentIntervals,
		],
		[
			'usergroupassignment__groupObject__usergroup__paymentInterval',
			null,
			gettext('Zahlungsintervall (Mitgliedschaftsgruppe)'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			paymentIntervals,
		],
		[
			'contactDetails__methodOfPayment',
			FILTER_INPUT_TYPE.SELECT,
			gettext('Zahlungsart'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			paymentmethods,
		],
		[
			'contactDetails__datevAccountNumber',
			null,
			gettext('Debitorennummer'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__bankAccountOwner',
			null,
			gettext('Abweichender Kontoinhaber'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__bic',
			null,
			gettext('Konto BIC'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__iban',
			null,
			gettext('Konto IBAN'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__sepaMandate',
			null,
			gettext('Mandatsreferenz'),
			null,
			FILTER_TYPE.STRING,
		],
		[
			'contactDetails__balance',
			null,
			gettext('Guthaben'),
			null,
			FILTER_TYPE.DOUBLE,
		],
		[
			'contactDetails___totalInvoiceBalance',
			null,
			gettext('Saldo'),
			null,
			FILTER_TYPE.DOUBLE,
		],
		[
			'contactDetails__invoice__invoicecalldocument__callState',
			FILTER_INPUT_TYPE.SELECT,
			gettext('Offene Mahnstufe'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			callStateData,
		],
	]

	const otherFilterTab = [
		[
			'hasOpenInvoices',
			null,
			gettext('Hat offene Rechnungen'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Ja'), 0: gettext('Nein') },
		],
	]

	const dosbFilter =
		dosbSports === null
			? []
			: [
					[
						'integrationDosbSport',
						FILTER_INPUT_TYPE.SINGLE_SELECT,
						gettext('Sport (DOSB)'),
						null,
						FILTER_TYPE.INTEGER,
						dosbSports,
					],
					[
						'integrationDosbGender',
						FILTER_INPUT_TYPE.SELECT,
						gettext('Geschlecht (DOSB)'),
						null,
						FILTER_TYPE.STRING,
						{
							w: gettext('Weiblich'),
							m: gettext('Männlich'),
							d: gettext('Divers'),
						},
					],
			  ]

	const lsbFilter =
		lsbSports === null
			? []
			: [
					[
						'integrationLsbSport',
						FILTER_INPUT_TYPE.SINGLE_SELECT,
						gettext('Fachverband (LSB)'),
						null,
						FILTER_TYPE.INTEGER,
						lsbSports,
					],
					[
						'integrationLsbGender',
						FILTER_INPUT_TYPE.SELECT,
						gettext('Geschlecht (LSB)'),
						null,
						FILTER_TYPE.STRING,
						{
							w: gettext('Weiblich'),
							m: gettext('Männlich'),
							d: gettext('Divers'),
						},
					],
			  ]

	const filters = []
	createFilter(filters, contactDataTabFilter, gettext('Kontaktdaten'))
	createFilter(filters, membershipTabFilter, gettext('Mitgliedschaft'))
	createFilter(
		filters,
		membersipFeeTabFilter,
		gettext('Mitgliedschaftsgebühren')
	)
	createFilter(filters, dosbFilter, gettext('DOSB'))
	createFilter(filters, lsbFilter, gettext('LSB'))
	createFilter(filters, otherFilterTab, gettext('Sonstige Filter'))
	buildCustomfieldFilters(filters, 'usercustomfield__')
	buildPasscreatorFilters(filters)

	const queryBuilderSettings = {
		allow_groups: true,
		display_errors: false,
		allow_empty: true,
		filters: filters,
	}
	createQueryBuilder('#builder', queryBuilderSettings, true, true)
}

/**
 * Create invontory filters
 */
export function buildInventoryFilters() {
	document.querySelector('#filter').dataset.filterModel = 'inventoryFilter'
	$('#builder').queryBuilder('destroy')
	const groupsList = getContextData('groupsList')
	const groupValues = {}
	groupsList.forEach((group) => {
		groupValues[group[0]] = group[1]
	})

	const locationsList = getContextData('locationsList')
	const locationValues = {}
	locationsList.forEach((group) => {
		locationValues[group[0]] = group[1]
	})

	const inventoryFilter = [
		['identifier', null, gettext('Artikelnummer'), null, FILTER_TYPE.STRING],
		['description', null, gettext('Beschreibung'), null, FILTER_TYPE.STRING],
		['name', null, gettext('Artikelname'), null, FILTER_TYPE.STRING],
		[
			'inventorygroupassignment',
			FILTER_INPUT_TYPE.SINGLE_SELECT,
			gettext('Inventargruppe'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			groupValues,
		],
		[
			'pieces',
			FILTER_INPUT_TYPE.TEXT,
			gettext('Stückzahl'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
		],
		['price', null, gettext('Einkaufspreis'), null, FILTER_TYPE.DOUBLE],
		[
			'locationObject_id',
			null,
			gettext('Standort / Lagerort'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			locationValues,
		],
		[
			'lending__borrowAddress',
			(rule, name) =>
				`<input class="addressSuggestion form-control" type="text"><input name="${name}" class="hidden" type="hidden">`,
			gettext('Ausgeliehen von'),
			null,
			FILTER_TYPE.STRING_ONLY_EQUAL,
			null,
			null,
			null,
			function (rule, value) {
				getModelsFromApi(
					function (data) {
						rule.$el.find('.addressSuggestion').val(data.data[0].name)
					},
					'Address',
					{ pk: value },
					9999,
					1,
					'',
					false,
					['name']
				)
			},
		],
		[
			'lending__state',
			FILTER_INPUT_TYPE.SELECT,
			gettext('Status der Ausleihe'),
			null,
			FILTER_TYPE.STRING_ONLY_EQUAL,
			{
				lent: gettext('Ausgeliehen'),
				inquiry: gettext('Anfrage'),
				returned: gettext('Zurückgegeben'),
			},
		],
	]

	const filters = []
	createFilter(filters, inventoryFilter)
	buildCustomfieldFilters(filters, 'customfield-')

	const queryBuilderSettings = {
		allow_groups: true,
		display_errors: false,
		allow_empty: true,
		filters: filters,
	}
	createQueryBuilder('#builder', queryBuilderSettings, false, false)
	buildFilterRules('#inventoryFilter')
	loadSessionFilter('inventoryFilter')
}

/**
 * Adds an event listener to the 'addFilterButton' element that, when clicked,
 * updates the innerText of the specified filterModel element with the current rules
 *
 * @param {string} filterModel the filter model id
 */
function buildFilterRules(filterModel) {
	document.getElementById('addFilterButton').addEventListener('click', () => {
		document.querySelector(filterModel).innerText = JSON.stringify(
			$('#builder').queryBuilder('getRules')
		)
	})
}

/**
 * Create article filters
 */
export function buildArticleFilters() {
	document.querySelector('#filter').dataset.filterModel = 'articleFilter'
	$('#builder').queryBuilder('destroy')

	const billingAccounts = getContextData('billingAccount')
	const locationsList = getContextData('locationsList')
	const locationValues = {}
	locationsList.forEach((group) => {
		locationValues[group[0]] = group[1]
	})

	const articleFilter = [
		['identifier', null, gettext('Artikelnummer'), null, FILTER_TYPE.STRING],
		['name', null, gettext('Artikelname'), null, FILTER_TYPE.STRING],
		['description', null, gettext('Beschreibung'), null, FILTER_TYPE.STRING],
		[
			'pieces',
			FILTER_INPUT_TYPE.TEXT,
			gettext('Stückzahl'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
		],
		['purchasePrice', null, gettext('Einkaufspreis'), null, FILTER_TYPE.DOUBLE],
		[
			'locationObject_id',
			null,
			gettext('Standort / Lagerort'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			locationValues,
		],
		['sellingPrice', null, gettext('Verkaufspreis'), null, FILTER_TYPE.DOUBLE],
		[
			'gross',
			null,
			gettext('zuzüglich/inklusive'),
			null,
			FILTER_TYPE.BOOL,
			{ 0: gettext('zzgl.'), 1: gettext('inkl.') },
		],
		// ['taxRate', null, gettext('Mehrwertsteuer'), null, FILTER_TYPE.DOUBLE],
		[
			'taxRate',
			FILTER_INPUT_TYPE.SELECT,
			gettext('Steuersatz'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			{
				0: gettext('0.00% Mehrwertsteuer'),
				7: gettext('7.00% Mehrwertsteuer'),
				19: gettext('19.00% Mehrwertsteuer'),
				5: gettext('5.00% Mehrwertsteuer'),
				16: gettext('16.00% Mehrwertsteuer'),
			},
		],
		[
			'billingAccount',
			FILTER_INPUT_TYPE.SINGLE_SELECT,
			gettext('Buchungskonto'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			billingAccounts,
		],
		['costCentre', null, gettext('Kostenstelle'), null, FILTER_TYPE.STRING],
		['earningPercent', null, gettext('Marge (%)'), null, FILTER_TYPE.DOUBLE],
		['earningAbsolute', null, gettext('Marge'), null, FILTER_TYPE.DOUBLE],
	]

	const filters = []
	createFilter(filters, articleFilter)

	const queryBuilderSettings = {
		allow_groups: true,
		display_errors: false,
		allow_empty: true,
		filters: filters,
	}
	createQueryBuilder('#builder', queryBuilderSettings, false, false)
	buildFilterRules('#articleFilter')
	loadSessionFilter('articleFilter')
}

/**
 * Create invontory filters
 */
export function buildEventFilters() {
	const groups = {}

	// Id, input type (if null: is depending on data type), label, placeholder (optional), data type, values for selects (optional), plugin type (optional), plugin config (optional), value setter (optional)
	const eventFilter = [
		[
			'eventgroupassignment',
			FILTER_INPUT_TYPE.SINGLE_SELECT,
			gettext('Kalender'),
			null,
			FILTER_TYPE.INTEGER,
			groups,
		],
		[
			'locationObject',
			null,
			gettext('Ort'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			groups,
		],
	]

	const filters = []
	createFilter(filters, eventFilter)

	const queryBuilderSettings = {
		allow_groups: true,
		allow_empty: true,
		filters: filters,
	}
	createQueryBuilder('#builder', queryBuilderSettings)
	// $('#builder').queryBuilder('setRules', filters)
}

/**
 * Create address filters
 *
 * @param {Array} data - The results of the `address-group` endpoint, it should look like this: [{'id': $id, 'name': $name}]
 */
export function buildAddressFilters(data) {
	buildGroups(data)
	const setDatemaskValue = (rule, value) => {
		rule.$el.find('.datemask').val(value)
	}
	const getDatemaskValue = (rule) => {
		return rule.$el.find('.datemask').value
	}

	const orgShowPrivateContactData = getContextData('orgShowPrivateContactData')
	const orgShowCompanyContactData = getContextData('orgShowCompanyContactData')

	// Id, input type (if null: is depending on data type), label, placeholder (optional), data type, values for selects (optional), plugin type (optional), plugin config (optional), value setter (optional)
	const addressDataTabFilter = [
		['salutation', null, gettext('Anrede'), null, FILTER_TYPE.STRING],
		['nameAffix', null, gettext('Titel'), null, FILTER_TYPE.STRING],
		['firstName', null, gettext('Vorname'), null, FILTER_TYPE.STRING],
		['familyName', null, gettext('Nachname'), null, FILTER_TYPE.STRING],
		[
			'dateOfBirth',
			null,
			gettext('Geburtstag'),
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		],
	]

	const contactDataPrivateFilter = []

	const companyTab = []

	const bankAccountDataTab = [
		[
			'bankAccountOwner',
			null,
			gettext('Abweichender Kontoinhaber'),
			null,
			FILTER_TYPE.STRING,
		],
		['bic', null, gettext('Konto BIC'), null, FILTER_TYPE.STRING],
		['iban', null, gettext('Konto IBAN'), null, FILTER_TYPE.STRING],
	]

	const sepaTab = [
		['sepaMandate', null, gettext('Mandatsreferenz'), null, FILTER_TYPE.STRING],
		['sepaDate', null, gettext('Mandatsdatum'), 'dd.mm.YYYY', FILTER_TYPE.DATE],
		[
			'methodOfPayment',
			FILTER_INPUT_TYPE.SELECT,
			gettext('Zahlungsart'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			buildQueryBuilderPairs(getContextData('methodOfPaymentChoices')),
		],
	]

	const otherFilterTab = [
		[
			'preferredCommunicationWay',
			null,
			gettext('Bevorzugte Kommunikationsform'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			{
				0: gettext('E-Mail'),
				1: gettext('Brief'),
				2: gettext('Keine Kommunikation'),
			},
		],
		[
			'orguser__isnull',
			FILTER_INPUT_TYPE.SELECT,
			gettext('Gehört zu Mitglied'),
			null,
			FILTER_TYPE.INTEGER_NOT_NULL,
			{ 0: gettext('Ja'), 1: gettext('Nein') },
		],
		[
			'orguser___isApplication',
			FILTER_INPUT_TYPE.SELECT,
			gettext('Offener Mitgliedschaftsantrag'),
			null,
			FILTER_TYPE.BOOL,
			{ false: gettext('Ja'), true: gettext('Nein') },
		],
		[
			'invoice__paymentDifference',
			null,
			gettext('Hat offene Rechnungen'),
			null,
			FILTER_TYPE.BOOL,
			{ 1: gettext('Ja'), 0: gettext('Nein') },
		],
	]

	if (orgShowPrivateContactData) {
		addressDataTabFilter.push(
			[
				'street',
				null,
				gettext('Straße & Hausnummer'),
				null,
				FILTER_TYPE.STRING,
			],
			['zip', null, gettext('Postleitzahl'), null, FILTER_TYPE.STRING],
			['city', null, gettext('Stadt'), null, FILTER_TYPE.STRING],
			['country', null, gettext('Land'), null, FILTER_TYPE.STRING],
			['state', null, gettext('Bundesland'), null, FILTER_TYPE.STRING]
		)

		contactDataPrivateFilter.push(
			[
				'privatePhone',
				null,
				gettext('Telefonnummer (priv.)'),
				null,
				FILTER_TYPE.STRING,
			],
			['mobilePhone', null, gettext('Handynummer'), null, FILTER_TYPE.STRING],
			[
				'privateEmail',
				null,
				gettext('E-Mail (priv.)'),
				null,
				FILTER_TYPE.STRING,
			]
		)
	}
	if (orgShowCompanyContactData) {
		companyTab.push(
			[
				'_isCompany',
				null,
				gettext('Adresstyp'),
				null,
				FILTER_TYPE.INTEGER_NOT_NULL,
				{ 0: gettext('Person'), 1: gettext('Firma') },
			],
			['companyName', null, gettext('Firmenname'), null, FILTER_TYPE.STRING],
			[
				'invoiceCompany',
				null,
				gettext('Rechnungen an Firma stellen'),
				null,
				FILTER_TYPE.BOOL,
				{ 1: gettext('Ja'), 0: gettext('Nein') },
			],
			[
				'sendInvoiceCompanyMail',
				null,
				gettext('E-Mail für Rechnungen (geschäftlich) in Rechnungen verwenden'),
				null,
				FILTER_TYPE.BOOL,
				{ 1: gettext('Ja'), 0: gettext('Nein') },
			],
			[
				'addressCompany',
				null,
				gettext('Briefe an Firma adressieren'),
				null,
				FILTER_TYPE.BOOL,
				{ 1: gettext('Ja'), 0: gettext('Nein') },
			],
			['professionalRole', null, gettext('Position'), null, FILTER_TYPE.STRING],
			[
				'companyStreet',
				null,
				gettext('Straße & Hausnummer (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'companyZip',
				null,
				gettext('Postleitzahl (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'companyCity',
				null,
				gettext('Stadt (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'companyCountry',
				null,
				gettext('Land (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'companyState',
				null,
				gettext('Bundesland (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'companyPhone',
				null,
				gettext('Telefonnummer (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'companyEmail',
				null,
				gettext('E-Mail (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			],
			[
				'companyEmailInvoice',
				null,
				gettext('E-Mail für Rechnungen (gesch.)'),
				null,
				FILTER_TYPE.STRING,
			]
		)
	}

	if (data.length > 0) {
		const pairs = []
		const results = data
		for (const entry of results) {
			pairs.push([entry.id, entry.name])
		}
		otherFilterTab.push([
			'addressgroupassignment',
			FILTER_INPUT_TYPE.SINGLE_SELECT,
			gettext('Adressgruppe'),
			null,
			FILTER_TYPE.INTEGER,
			buildQueryBuilderPairs(pairs),
		])
	}

	const customFilters = []
	for (const field of getContextData('customFields')) {
		const addressCustomField = [`addresscustomfield__${field.id}`]

		if (['s', 'a'].includes(field.settings_type)) {
			addressCustomField.push(FILTER_INPUT_TYPE.SELECT)
		} else if (['d', 'm'].includes(field.settings_type)) {
			addressCustomField.push(
				(rule, name) =>
					'<input class="datemask form-control" autocomplete="off">'
			)
		} else {
			addressCustomField.push(null)
		}

		addressCustomField.push(field.name)

		const customFieldAdditional = field.additional || '{}'
		const additional = Array.from(
			JSON.parse(customFieldAdditional),
			(option) => {
				return { [option]: option }
			}
		)
		const kind = {
			b: [null, FILTER_TYPE.FILE],
			c: [null, FILTER_TYPE.BOOL, { 1: gettext('Ja'), 0: gettext('Nein') }],
			d: [
				'dd.mm.YYYY',
				FILTER_TYPE.DATE,
				null,
				'datepicker',
				{ format: 'dd.mm.YYYY' },
				(rule, value) => setDatemaskValue(rule, value),
				(rule) => getDatemaskValue(rule),
			],
			m: [
				'dd.mm.YYYY',
				FILTER_TYPE.DATE,
				null,
				'datepicker',
				{ format: 'dd.mm.YYYY' },
				(rule, value) => setDatemaskValue(rule, value),
				(rule) => getDatemaskValue(rule),
			],
			r: ['dd.mm.YYYY', FILTER_TYPE.DATE],
			s: [
				null,
				FILTER_TYPE.STRING,
				[{ '': 'Keine Auswahl' }].concat(additional),
			],
			a: [
				null,
				FILTER_TYPE.MULTI_VALUE,
				additional,
				FILTER_PLUGN_TYPE.CHOSEN,
				{
					no_results_text: gettext('Kein auswählbaren Optionen'),
					width: '300',
					placeholder_text_multiple: `${field.name} ${gettext('wählen')}`,
					multiple: true,
				},
			],
			t: [null, FILTER_TYPE.STRING],
			f: [null, FILTER_TYPE.STRING],
		}[field.settings_type]
		addressCustomField.concat(kind)

		customFilters.push(addressCustomField)
	}

	const filters = []
	createFilter(filters, addressDataTabFilter, gettext('Adresse & Daten'))
	createFilter(
		filters,
		contactDataPrivateFilter,
		gettext('Kontaktdaten (priv.)')
	)
	createFilter(filters, companyTab, gettext('Geschäftlich'))
	createFilter(filters, bankAccountDataTab, gettext('Kontodaten'))
	createFilter(filters, sepaTab, gettext('SEPA Lastschriftmandat'))
	createFilter(filters, otherFilterTab, gettext('Sonstige Filter'))
	createFilter(filters, customFilters, gettext('Individuelle Felder'))
	buildCustomfieldFilters(filters, 'addresscustomfield__')

	const queryBuilderSettings = {
		allow_groups: true,
		display_errors: false,
		allow_empty: true,
		filters: filters,
	}
	createQueryBuilder('#builder', queryBuilderSettings)
}

/**
 * Loads session filter
 *
 * @param {string} filterName - The id of the filter
 */
export function loadSessionFilter(filterName = undefined) {
	const sessionFilter = getContextData(filterName || 'sessionFilterData')
	if (sessionFilter !== '' && sessionFilter !== 0) {
		sessionFilter.rules = changeValueFormat(sessionFilter.rules)
		$('#builder').queryBuilder('setRules', sessionFilter)
		showFilterActivationNotice(undefined, '', sessionFilter)
	}
}

/**
 * Creates the customfield filters for the query builder
 *
 * @param {Array} filters - The created filter will be pushed to this array
 * @param {string} customfieldPrefix - Prefix for fieldId
 */
export function buildCustomfieldFilters(filters, customfieldPrefix) {
	const customFieldsData = getContextData('customFieldsData')
	const customFilters = []
	for (const idn in customFieldsData) {
		if (Object.hasOwnProperty.call(customFieldsData, idn)) {
			const data = customFieldsData[idn]
			let fieldId = `${customfieldPrefix}${idn}`
			let label = data.name
			let inputType = null
			let placeholder = null
			let dataType = null
			let selectValues = null
			switch (data.settings_type) {
				case 'd':
				case 'm':
					// Date/Reminder
					placeholder = 'dd.mm.YYYY'
					dataType = FILTER_TYPE.DATE
					break
				case 'b':
					// File
					dataType = FILTER_TYPE.FILE
					break
				case 'c':
					// Boolean
					dataType = FILTER_TYPE.BOOL
					selectValues = { 1: gettext('Ja'), 0: gettext('Nein') }
					break
				case 'r':
					// Date Range
					fieldId = `${customfieldPrefix}${idn}__end`
					label = `${data.name} ${gettext('Ende')}`
					placeholder = 'dd.mm.YYYY'
					dataType = FILTER_TYPE.DATE_RANGE
					customFilters.push([
						`${customfieldPrefix}${idn}__start`,
						inputType,
						`${data.name} ${gettext('Start')}`,
						placeholder,
						dataType,
						selectValues,
					])
					break
				case 'a':
				case 's':
					// Select (s) / Multi Select (a)
					inputType = FILTER_INPUT_TYPE.SELECT
					dataType = FILTER_TYPE.STRING_SELECT
					selectValues = {}
					if (data.additional === '') {
						data.additional = '[]'
					}
					JSON.parse(data.additional).forEach((option) => {
						selectValues[option] = `${option}`
					})
					break
				case 't':
				case 'f':
					// Text/Multiline
					dataType = FILTER_TYPE.STRING
					break
				case 'z':
					// Number
					dataType = FILTER_TYPE.DOUBLE
					break
				default:
					break
			}
			customFilters.push([
				fieldId,
				inputType,
				label,
				placeholder,
				dataType,
				selectValues,
			])
		}
	}

	createFilter(filters, customFilters, gettext('Individuelle Felder'))
}

/**
 * Creates the passcreator filters for the query builder
 *
 * @param {Array} filters - The created filter will be pushed to this array
 */
export function buildPasscreatorFilters(filters) {
	const hasEnabledPasscreatorIntegration = getContextData(
		'hasEnabledPasscreatorIntegration'
	)
	if (hasEnabledPasscreatorIntegration) {
		const passcreatorTemplates = getContextData('passcreatorTemplateNames')
		const templateFilter = passcreatorTemplates.map((template) => [
			`passcreatorpass__${template.pk}`,
			null,
			`${gettext('Letzter Druck von')} "${template.name}"`,
			'dd.mm.YYYY',
			FILTER_TYPE.DATE,
		])
		templateFilter.push([
			'passcreatorpass__pass_id',
			null,
			gettext('Ausweis-ID'),
			null,
			FILTER_TYPE.STRING,
		])
		createFilter(filters, templateFilter, gettext('Mitgliedsausweise'))
	}
}

/**
 * Creates a bootstrap 5 selection box for single select
 *
 * @param {object} rule - a querybuilder `Rule` object
 * @param {string} name - name of the input
 * @returns {string} template string of the select tag
 */
function bootstrap5SelectionBoxInputType(rule, name) {
	let html = `<select class="form-select" name="${name}">`
	html += _generateOptionsHtml(rule.filter.values)
	html += '</select>'
	return html
}

/**
 * Creates a single select SelectUs
 *
 * @param {object} rule - a querybuilder `Rule` object
 * @param {string} name - name of the input
 * @returns {string} template string of the custom selection box
 */
function customSingleSelectBoxInputType(rule, name) {
	return customSelectBox(rule, name, false)
}

/**
 * Creates a multi select SelectUs
 *
 * @param {object} rule - a querybuilder `Rule` object
 * @param {string} name - name of the input
 * @returns {string} template string of the custom selection box
 */
function customMultiSelectBoxInputType(rule, name) {
	return customSelectBox(rule, name, true)
}

/**
 * Creates a SelectUs for the QueryBuilder
 *
 * @param {object} rule - a querybuilder `Rule` object
 * @param {string} name - name of the input
 * @param {boolean} multiple - if true the select is a multi select
 * @returns {string} template string of the custom selection box
 */
function customSelectBox(rule, name, multiple) {
	let html = `<select-us small data-for="${
		rule.filter.id
	}"><select name="${name}"${multiple ? ' multiple' : ''}>`
	html += _generateOptionsHtml(rule.filter.values)
	html += '</select></select-us>'
	return html
}

/**
 * Generate a template string of <option> tags for (multi) select input types
 *
 * @param {object} options - object with {value: label} pairs
 * @returns {string} template string with <option> tags
 */
function _generateOptionsHtml(options) {
	let html = ''
	if (Array.isArray(options)) {
		const optgroups = options.reduce((optgroups, option) => {
			if (!(option.optgroup in optgroups)) {
				optgroups[option.optgroup] = []
			}
			optgroups[option.optgroup].push(option)
			return optgroups
		}, {})
		Object.entries(optgroups).forEach(([label, options]) => {
			const hasOptgroup = label !== 'undefined'
			if (hasOptgroup) html += `<optgroup label="${label}">`
			options.forEach((option) => {
				html += `<option value="${option.value}">${option.label}</option>`
			})
			if (hasOptgroup) html += '</optgroup>'
		})
	} else {
		Object.entries(options).forEach((pair) => {
			html += `<option value="${pair[0]}">${pair[1]}</option>`
		})
	}
	return html
}

/**
 * Generate all date time based customFilter-like filters (for bookkeeping as well as for logs)
 *
 * @param {object} dateranges - mapping date tuples (start, end) for the given date range, usually loaded from context data
 * @returns {Array<Array<any>>} returns array of filter configuration arrays
 */
export function dateFilters(dateranges) {
	const filterData = [
		{ key: '#filterCompleteTime', value: dateranges.allTime },
		{ key: '#filterBusinessYear', value: dateranges.businessYear },
		{ key: '#filterThisYear', value: dateranges.thisYear },
		{ key: '#filterLastYear', value: dateranges.lastYear },
		{ key: '#filterThisQuarter', value: dateranges.thisQuater },
		{ key: '#filterLastQuarter', value: dateranges.lastQuater },
		{ key: '#filterThisMonth', value: dateranges.thisMonth },
		{ key: '#filterLastMonth', value: dateranges.lastMonth },
		{
			key: '#filterLastThreeMonths',
			value: dateranges.lastThreeMonths,
		},
		{ key: '#filterLastSevenDays', value: dateranges.lastWeek },
		{ key: '#filterFourteenDays', value: dateranges.lastTwoWeeks },
		{ key: '#filterThirtyDays', value: dateranges.last30Days },
	]
	// The first 'date' filter needs to override to clear existing filters, otherwise we end up with multiple with the same operators
	return filterData
		.filter((data) => data.value && data.value.length === 2)
		.map((data) => [
			data.key,
			[
				['date', 'date', 'greater_or_equal', data.value[0], true],
				['date', 'date', 'less_or_equal', data.value[1], false],
			],
		])
}

/**
 * Generates filters for invoice/bookings state on `paymentDifference
 *
 * @returns {Array<Array<any>>} array with arrays of filter definitions
 */
export function bookkeepingStateFilters() {
	if (window.location.pathname.includes('/invoices/')) {
		return [
			[
				'#invoiceFilterShowOpenOnly',
				[['paymentDifference', 'integer', 'greater', 0, true]],
			],
			[
				'#invoiceFilterIgnoreBookingstatus',
				[['paymentDifference', undefined, undefined, undefined, true]],
			],
		]
	}
	return [
		[
			'#invoiceFilterShowOpenOnly',
			[['paymentDifference__booking', 'integer', 'equal', 0, true]],
		],
		[
			'#invoiceFilterIgnoreBookingstatus',
			[['paymentDifference__booking', undefined, undefined, undefined, true]],
		],
	]
}

/**
 * Generate an object from the methodOfPaymentChoices
 *
 * @param {Array} list - An array containing Arrays of key and value
 * @returns {object} - An object representing the QueryBuilder pairs
 */
export function buildQueryBuilderPairs(list) {
	const object = {}

	for (const pairs of list) {
		object[pairs[0]] = pairs[1]
	}
	return object
}
