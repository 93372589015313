/**
 * Standard set of delays in ms.
 *
 * Developers should not have to think about how many ms they actually need to wait. Estimation is enough
 */
export const delay = {
	default: 500,
	short: 300,
	long: 1000,
	none: 0,
}
