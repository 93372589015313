import gtag, { install } from 'ga-gtag'

/**
 * Default gtag configuration for Google Ads.
 */
export function setupGlobalSiteTag() {
	install('AW-981865377')
	window.dataLayer = window.dataLayer || []
	gtag('js', new Date())
	gtag('config', 'AW-981865377', { anonymize_ip: true })
	gtag('event', 'conversion', { send_to: 'AW-981865377/66lZCN7Hpm0QoaeY1AM' })
}

/**
 * init google analytics for #11361
 */
export function setupTagManager() {
	;(function (w, d, s, l, i) {
		w[l] = w[l] || []
		w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
		const f = d.getElementsByTagName(s)[0]
		const j = d.createElement(s)
		const dl = l !== 'dataLayer' ? '&l=' + l : ''
		j.async = true
		j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
		f.parentNode.insertBefore(j, f)
	})(window, document, 'script', 'dataLayer', 'GTM-NDBDD8C9')
}

/**
 * set values in dataLayer for #11549 / #11577
 *
 * @param {boolean} isDemo - is demo true/false
 * @param {boolean} isQuickRegister - is quick-register true/false
 */
export function updateDataLayerForAnalytics(isDemo, isQuickRegister) {
	window.dataLayer = window.dataLayer || []

	let registerFormType
	if (isQuickRegister) {
		registerFormType = 'LandingPageQuickForm'
	} else {
		registerFormType = isDemo ? 'Demo' : 'Lizenz'
	}

	window.dataLayer.push({
		event: 'form_erfolgreich',
		registerFormType: registerFormType,
	})
}
