import { Modal } from 'bootstrap'
import { getMessageText } from '../../../globals/messages'
import { getContextData } from '../../../utils/django'
import { hideElement, showElement, show } from '../../../utils/helpers/show'
import { isObject } from '../../../utils/helpers/validators'
import { escapeHtml } from '../../../utils/html'
import { gettext } from '../../../utils/translation'

/**
 * Checks the permissions of the current user for the Bookings module
 *
 * @returns {boolean} true if the current user has `bookingsPermission >= 'w'`
 */
export function hasBookingsWritingPermissions() {
	return getContextData('bookingsPermission') === 'w'
}

/**
 * Checks if the current user has tooltips enabled
 *
 * @returns {boolean} true if the user has tooltips enabled
 */
export function hasEnabledTooltips() {
	return getContextData('tooltipsEnabled')
}

/**
 * Creates the HTML attributes for a simple tooltip
 *
 * @param {string} text - tooltip text (already wrapped in `gettext` or similar)
 * @param {string} [position="auto"] - position of the tooltip. Can be `'auto'` [default], `'top'`, `'bottom'`, `'left'`, or `'right'`.
 * @returns {string} HTML attributs for the tooltip
 */
export function createTooltipAttribute(text, position = 'auto') {
	if (!hasEnabledTooltips()) return ''
	return `data-bs-toggle="tooltip" data-bs-placement="${position}" title="${text}"`
}

/**
 * Deep copies an element and removes the `.template` class from the copy
 *
 * @param {Element} template - element to clone
 * @returns {Element} deep copy of `template`
 */
export function cloneTemplate(template) {
	const copy = template.cloneNode(true)
	copy.classList.remove('template')
	return copy
}

/**
 * Retrieves the name of the bookings group
 *
 * @param {object} booking - `Booking` instance from the backend
 * @returns {string} name of the group or empty string
 */
export function getGroupName(booking) {
	if (!booking.group || !booking.group.name) {
		return ''
	}
	return booking.group.name
}
/**
 * Retrieves the names of assigned billing account
 *
 * @param {object} booking - `Booking` instance from the backend
 * @param {Array<Element>} warningNodes - elements that may show warnings
 * @param {boolean} table - booking is shown in a table
 * @returns {string} html template string
 */
export function getGroupTitle(booking, warningNodes = [], table = false) {
	let title = ''
	if (!table) {
		const group = getGroupName(booking)
		if (group !== '') {
			const costCentre =
				booking.group.projectCostCentre !== ''
					? `-- (${gettext('KSt:')} ${booking.group.projectCostCentre})`
					: ''
			title = `<br><small>${group} ${costCentre}</small>`
		}
	}
	title += '<br>'
	warningNodes.forEach(hideElement)
	if (isObject(booking.billingAccount)) {
		booking.billingAccount.name = escapeHtml(booking.billingAccount.name)
		const billingAccountName = `${booking.billingAccount.number} ${booking.billingAccount.name}`
		let billingAccountsMatching = false
		if (booking.meta.invoices.invoiceBillingAccounts?.length) {
			billingAccountsMatching =
				booking.meta.invoices.invoiceBillingAccounts.some(
					(account) =>
						billingAccountName === escapeHtml(account) &&
						(booking.meta.invoices.invoiceBillingAccounts.length === 1 ||
							booking.bookingAmount !== booking.price)
				)
		} else {
			billingAccountsMatching = true
		}

		if (billingAccountsMatching) {
			title += `<small>${billingAccountName}</small>`
		} else {
			title += `<small><i class="far fa-fw fa-exclamation-triangle text-danger"></i> ${gettext(
				'Zuordnung der Buchungskonten nicht eindeutig!'
			)}</small>`
			getMessageText(
				'BKx406xNotMatchingBillingAccounts',
				'',
				window.location.href,
				false
			).then((data) => {
				const messagetext = data.msg
				warningNodes.forEach((node) => {
					node.innerText = messagetext
					showElement(node)
				})
			})
		}
	} else if (booking.meta.invoices.invoiceBillingAccounts?.length) {
		title += `<small>${booking.meta.invoices.invoiceBillingAccounts.join(
			'<br>'
		)}</small>`
	}
	return title
}

/**
 * Removes `#invoice` and changes the history
 * `#invoice` contains a hidden field on the `modalEditBooking.html`.
 * It is only visible if `?invoice=` GET parameter is present
 */
export function removeInvoiceLink() {
	document.querySelector('#invoice')?.remove()
	window.history.pushState(null, '', '/app/bookkeeping/')
}

/**
 * Retrieves a Bootstrap modal instance from the selector
 *
 * @param {string} selector - ID of the modal
 * @returns {Modal} modal instance
 */
export function getModal(selector) {
	const modal = document.querySelector(selector)
	return Modal.getOrCreateInstance(modal)
}

/**
 * Retrieves the id of the project group
 *
 * @param {object} booking - booking instance
 * @returns {string} id of the project group
 */
export function getProjectGroupID(booking) {
	return getPotentiallyNestedField(booking, 'bookingProject', '-')
}

/**
 * Retrieves the id of the BookingAccount
 *
 * @param {object} booking - booking instance
 * @returns {string} id of the BookingAccount
 */
export function getBookingAccountID(booking) {
	return getPotentiallyNestedField(booking, 'billingAccount')
}

/**
 * Retrieves the id of the BankAccount
 *
 * @param {object} booking - booking instance
 * @returns {string} id of the BankAccount
 */
export function getBankAccountID(booking) {
	return getPotentiallyNestedField(booking, 'bankAccount', '0')
}

/**
 * Retrieves the id of `field` either directly or
 * by getting it from the nested object.
 *
 * @param {object} booking - booking instance
 * @param {string} field - key of the booking object to retrieve
 * @param {string} defaultValue - value to use when no id can be found, usually the value of the "none" option in selects
 * @returns {string} id of the field
 */
function getPotentiallyNestedField(booking, field, defaultValue = '-1') {
	let value = defaultValue
	if (!booking) return value
	if (booking[field]) {
		value = booking[field]
		if (isObject(booking[field]) && (booking[field].pk || booking[field].pk)) {
			if (booking[field].id) {
				value = booking[field].id
			} else {
				value = booking[field].pk
			}
		}
	}
	return value
}

/**
 * returns the result html of the pain validation
 *
 * @param {boolean} success - validation success or not
 * @param {object} validation - dict of error or sucess messages
 * @returns {string} - validation result html
 */
export function buildPAINValidationResult(success, validation) {
	if (success) {
		return `<p>${validation.successMessage}</p>`
	}

	/**
	 * create error list accordionItem
	 *
	 * @param {string} errorType - type of the error (Etree, Address, Account)
	 * @param {object} errors - error list
	 * @param {string} title - title of the accordionItem
	 * @returns {string} - reutrn accordionItem html
	 */
	function addAccordionItem(errorType, errors, title) {
		return `
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-heading${errorType}Errors">
					<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse${errorType}Errors" aria-expanded="false" aria-controls="flush-collapse${errorType}Errors">
						<span class="d-flex justify-content-center w-100 fs-5">
							${title}&nbsp;&nbsp;<i class="far fa-angle-down"></i>
						<span>
					</button>
				</h2>
				<div id="flush-collapse${errorType}Errors" class="accordion-collapse collapse" aria-labelledby="flush-heading${errorType}Errors" data-bs-parent="#accordionPAINErrors">
					<div class="accordion-body">
						${errors}
					</div>
				</div>
			</div>`
	}

	let html = "<div class='accordion accordion-flush' id='accordionPAINErrors'>"

	if (validation.eTreeErrors) {
		html += addAccordionItem(
			'Etree',
			validation.eTreeErrors,
			gettext('Prüfungsergebnis ansehen')
		)
	}

	const addressErrors = validation.addressErrors
	if (addressErrors) {
		const addresIntegrityCheckLinkHTML = `
			<br><br>
			<a
				target="_blank"
				href=${encodeURI(
					'/app/integrity/?mode=check&autoStartFromPainValidation=true&checkAccountFields=false&checkSepaFields=true&checkBankFields=true&checkTextFields=false&checkDateFields=false&checkEmailFields=false&userGroups=["all"]'
				)}>
				<i class="far fa-search-dollar"></i> ${gettext(
					'Alle Adressen auf ungültige Einstellungen für Lastschrifteinzüge prüfen'
				)}
			</a>
		`
		html += addAccordionItem(
			'Address',
			addressErrors + addresIntegrityCheckLinkHTML,
			gettext('Hinweise zu den Rechnungsadressen')
		)
	}

	const accountErrors = validation.accountErrors
	if (accountErrors) {
		html += addAccordionItem(
			'Account',
			accountErrors,
			gettext('Hinweise zu den Stammdaten')
		)
	}

	html += '</div>'

	return html
}

/**
 * Updates the modal to show cancel reason input
 *
 */
export function showCancelOption() {
	document.querySelector('#maCustomOption').innerHTML = `<hr>
		<div class="col-12 mb-3">
			<div class="form-floating mb-3">
				<input type="text" class="form-control" id="maCancellationDescription" placeholder="${gettext(
					'Stornierungsgrund'
				)}">
				<label for="maCancellationDescription"><i class="far fa-money-bill-wave-alt"></i> ${gettext(
					'Stornierungsgrund'
				)}</label>
			</div>
			<hr>
			<div class="form-check form-switch pg-0 d-flex align-items-center mt-4">
				<input type="checkbox" id="cancelOpenOrDownloadedCheckbox" class="form-check-input m-0 col-auto" value="1">
				<label for="cancelOpenOrDownloadedCheckbox" class="form-check-label px-4">${gettext(
					'Auch Rechnungen mit dem Zustand "Offen, heruntergeladen" stornieren? Sollte die SEPA Lastschriftdatei bereits bei der Bank eingereicht worden sein, kann es dadurch später Probleme beim Verbuchen des Einzugs geben.'
				)}</label>
			</div>
			<div class="form-check form-switch pg-0 d-flex align-items-center mt-4">
				<input type="checkbox" id="cancelBookedCheckbox" class="form-check-input m-0 col-auto" value="1">
				<label for="cancelBookedCheckbox" class="form-check-label px-4">${gettext(
					'Auch gebuchte, überbuchte und teilgebuchte Rechnungen stornieren? Die Zuordnung der Buchungen zur Rechnung wird damit aufgehoben. Die Rechnungen werden in vollem Umfang storniert. Teilstornos sind nicht möglich.'
				)}</label>
			</div>
			<hr>
			<div class="form-check form-switch pg-0 d-flex align-items-center mt-4">
				<input type="checkbox" id="addToBalance" class="form-check-input m-0 col-auto" value="1">
				<label for="addToBalance" class="form-check-label px-4">${gettext(
					'Soll in den Rechnungen verrechnetes Guthaben wieder im jeweiligen Mitglieder- oder Adressprofil hinterlegt werden?'
				)}</label>
			</div>
		</div>`
	show('#maCustomOption')
}
