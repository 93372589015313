import { escapeHtml } from './html.js'

/**
 * Changes the maximum rows of a textarea depending on newlines.
 *
 * @param {object} ele - textarea element
 * @returns {number} - rows + 1 or rows - 1
 */
export function autoExpandTextArea(ele) {
	if (
		(!ele?.target?.value && !ele?.value) ||
		(!ele?.target?.tagName?.toLowerCase() === 'textarea' &&
			!ele?.tagName?.toLowerCase() === 'textarea')
	) {
		return 'Invalid argument: ele'
	}

	ele = ele.target ? ele.target : ele

	ele.setAttribute('rows', ele.value.split('\n').length + 1)
}

/**
 * Creates the HTML string for the autocomplete labels.
 *
 * @param {object} suggestion - The suggestion object.
 * @param {string} mainProperty - The property name for the main label, displayed left.
 * @param {Array} properties - The property names for the sub labels, displayed right. Can contain a placerholder.
 * @param {string} seperator - The seperator for the sub labels.
 * @returns {string} The HTML string for the autocomplete labels.
 */
export function createAutocompleteLabel(
	suggestion,
	mainProperty,
	properties,
	seperator = ','
) {
	let subProperties = ''

	for (let i = 0; i < properties.length; i++) {
		let value = ''

		if (Array.isArray(properties[i])) {
			value = suggestion[properties[i][0]] || properties[i][1] || ''
		} else {
			value = suggestion[properties[i]]
		}

		if (subProperties === '') {
			subProperties = escapeHtml(value)
		} else {
			subProperties += `${seperator} ${escapeHtml(value)}`
		}
	}

	return `<div class="cursor-pointer">${escapeHtml(
		suggestion[mainProperty]
	)} <small class="float-end">${subProperties}</small></div>`
}

/**
 * Sets the is-invalid class on the input field if the value is empty.
 *
 * @param {Event} event - The event object.
 */
export function toggleRequiredInputValidity(event) {
	const field = event.currentTarget
	field.classList.toggle('is-invalid', field.required && field.value === '')
}
