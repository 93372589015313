import { internalError } from '../globals/messages'
import $ from 'jquery'
import PaginatedFetcher from '../app/logs/paginator'
import { alertDanger } from '../globals/alerts'

/**
 * Legacy "API" wrapper
 *
 * ! Do _NOT_ use this in new code !
 *
 * @deprecated This function should not be used in new code
 * @param {Function} callback - callback function that takes the result data as parameter
 * @param {string} model - string of the model to retrieve
 * @param {any} filter - API filter
 * @param {number | string} limit - results per page
 * @param {number | string} page - page to retrieve
 * @param {string} order - order by
 * @param {boolean} suggestion - retrieve suggestions instead of complety results
 * @param {any} fields - which fields to retrieve
 * @param {any} exclude - what to exclude
 * @param {boolean} showBirthdays - show birthdays
 * @param {number | string} org - id of the org
 * @param {object} extras - extra configuration
 * @param {PaginatedFetcher?} extras.fetcher - optional fetcher for log pages
 */
export function getModelsFromApi(
	callback,
	model,
	filter,
	limit,
	page,
	order,
	suggestion,
	fields,
	exclude,
	showBirthdays,
	org,
	{ fetcher } = {}
) {
	let extraParams = ''
	if (order === undefined) {
		order = ''
		page = ''
		limit = ''
	}
	if (fields === undefined) {
		fields = ''
	}
	if (suggestion === true) {
		extraParams += '&suggestion=1'
	}
	if (showBirthdays === true) {
		extraParams += '&showBirthdays=1'
	}
	if (org !== undefined) {
		extraParams += `&org=${org}`
	}
	if (fields !== undefined) {
		extraParams += '&fields=' + fields
		extraParams += '&exclude=' + JSON.stringify(exclude)
	}
	$.ajax({
		method: 'GET',
		url:
			'/app/api/requestmodel/' +
			model +
			'/?filter=' +
			JSON.stringify(filter) +
			'&limit=' +
			limit +
			'&page=' +
			page +
			'&order=' +
			order +
			extraParams,
	})
		.done(function (data) {
			if (fetcher) {
				fetcher.setMaxPage(data?.num_pages)
			}
			if (data.status === false) {
				alertDanger('APx500xInternalError', undefined, {
					messageData: '',
					locationIdentifier: window.location.href,
				})
			} else {
				return callback(data)
			}
		})
		.fail(function (data) {
			internalError(data)
		})
}
