/**
 * Passes the names to the backend were it is checked for blacklisted parts and general suspiciousness
 *
 * @param {string} firstName - first name to test
 * @param {string} familyName - family name to test
 * @returns {Promise<object>} promise of the fetch
 */
export function validateUserName(firstName, familyName) {
	return fetch(
		`/app/api/userNameValidator?firstName=${firstName}&familyName=${familyName}`
	)
}

/**
 * Checks if value is an Object
 *
 * @param {any} value - value to check
 * @returns {boolean} true if and only if `value` is an object
 */
export function isObject(value) {
	return value && typeof value === 'object' && value.constructor === Object
}

/**
 * Checks if a string contains a number
 *
 * @param {string} string - string to check for numbers
 * @returns {boolean} true if and only if `string` contains a number
 */
export function containsNumber(string) {
	return /^-?\d+(\.\d+)?$/.test(string)
}

/**
 * Checks if value is a Number
 *
 * @param {any} value - value to check
 * @returns {boolean} true if and only if `value` is a Number
 */
export function isNumber(value) {
	return Number.isFinite(value)
}
