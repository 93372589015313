import { getCookie, setCookie } from '../utils/cookies'
import { getModelsFromApi } from '../utils/getModelsFromApi'
import { getGlobals } from './django'
import { hide, show } from './helpers/show'

/**
 * Refreshes open tasks badge
 *
 * @param {boolean} forceRefresh Decides if tasks get force refreshed
 */
export function refreshOpenTasksBadge(forceRefresh = false) {
	if (!getCookie('openTasks') || forceRefresh) {
		// TODO: change to API call
		getModelsFromApi(
			setOpenTasksBadge,
			'Task',
			{ state: 'offen', member: getGlobals().userID },
			9999,
			1
		)
	} else {
		setOpenTasksBadge()
	}
}

/**
 * Sets open tasks badge
 *
 * @param {object} data - contains number of total tasks
 */
function setOpenTasksBadge(data = {}) {
	if (data.total !== undefined) {
		setCookie('openTasks', data.total, 0.001) // 0.001 equals roughly 15 minutes
	} else {
		data.total = getCookie('openTasks')
	}

	const openTasks = document.querySelector('#openTasks')
	if (data.total > 0) {
		show('#openTasks')
		openTasks.innerHTML = data.total
	} else {
		hide('#openTasks')
	}
}
