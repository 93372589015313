import moment from 'moment'

/**
 * Limits the first possible start date to be today
 *
 * @param {string} startDatePickerID - id of the start date picker
 * @param {string} endDatePickerID - id of the end date picker
 */
export function setStartdateMinToToday(startDatePickerID, endDatePickerID) {
	const rightNow = new Date()
	rightNow.setHours(rightNow.getHours() + 1)
	const startPicker = document.querySelector(`#${startDatePickerID}`)
	startPicker.datepicker.setOptions({
		minDate: rightNow,
	})
	const endPicker = document.querySelector(`#${endDatePickerID}`)
	endPicker.datepicker.setOptions({
		minDate: rightNow,
	})
}

/**
 * Limits the first possible end date to be the start date
 *
 * @param {event} event - vanillajs-datepicker changeDate event
 * @param {string} endDatePickerID - id of the end date picker
 */
export function setEnddateMinToStart(event, endDatePickerID) {
	const endPicker = document.querySelector(`#${endDatePickerID}`)
	const endValue = endPicker.value
	endPicker.datepicker.setOptions({
		minDate: event.currentTarget.value,
	})
	// Somehow, setting the options removes the value the field had, so set it back to what it was originally
	endPicker.value = endValue
}

/**
 * For triggering the changeDate event manually when a DatePicker receives a value change via code
 *
 * @param {string} selector - DOM Elements
 */
export function triggerChangeDateEvent(selector) {
	document.querySelectorAll(selector).forEach((element) => {
		if (moment(element.value, 'DD.MM.YYYY', true).isValid()) {
			element.datepicker.setDate(element.value)
		}
	})
}
