// This formatters are made to format the date and datetime to wrap them between the API and input fields using the datepicker

import moment from 'moment'

/**
 * Formating the date so the API accepts the date (DD.MM.YYYY => YYYY-MM-DD)
 *
 * @param {string} date - The date in the regular datemask format (DD.MM.YYYY)
 * @returns {string} - The date in the correct format for the API
 */
export function formatDateForAPI(date) {
	if (date) {
		return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
	}
	return date
}

/**
 * Formating the date from the API so the datepicker accepts it (YYYY-MM-DD => DD.MM.YYYY)
 *
 * @param {string} date - The date in the regular datemask format (YYYY-MM-DD)
 * @returns {string} - The date in the correct format for the API
 */
export function formatDateFromAPI(date) {
	if (date) {
		return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
	}
	return date
}

/**
 * Formating the datetime from the API so it is more human readable
 *
 * @param {string} datetime - The datetime in regular iso format (just the raw datetime coming from the api)
 * @returns {string} - The datetime in a more human readable format
 */
export function formatDateTimeFromAPI(datetime) {
	if (datetime) {
		return moment(datetime, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm')
	}
	return datetime
}

/**
 * Formating the date so the API accepts the date (DD.MM.YYYY => YYYY-MM-DDT00:00:00)
 * Sometimes a date is saved as datetime (this is stupid) so it must be a datetime
 *
 * @param {string} date - The date in the regular datemask format (DD.MM.YYYY)
 * @returns {string} - The date in the correct format for the API
 */
export function formatDateTimeForAPI(date) {
	if (date) {
		return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T00:00:00'
	}
	return date
}

/**
 * Formating a datetime to a date-string
 *
 * @param {string} datetime - The datetime in regular iso format
 * @returns {string} - The convertet date
 */
export function convertDateTimeToDate(datetime) {
	if (datetime) {
		return moment(datetime, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')
	}
	return datetime
}

/**
 * Formating the date so the API accepts the date (DD.MM.YYYY => YYYY-MM-DDT00:00:00)
 * Sometimes a date is saved as datetime (this is stupid) so it must be a datetime
 *
 * @param {string} date - The date in the regular datemask format (DD.MM.YYYY)
 * @param {string} time - The time in the regular time format
 * @returns {string} - The date in the correct format for the API
 */
export function formatDateTimeForAPIDateTime(date, time) {
	if (date && time) {
		const datetime = date + time
		return moment(datetime, 'DD.MM.YYYYHH:mm').format('YYYY-MM-DDTHH:mm:ss')
	}
	return ''
}

/**
 * Formats the datetime to the german time
 *
 * @param {Date} date - A date object
 * @returns {string|undefined} A string representation of the date or undefined if the date is nullable
 */
export function timeFormat(date) {
	if (!date) return
	// Using the german timezone by default because thats the time of the server
	const timeZoneCorrectedDate = date.toLocaleString('de-DE', {
		timeZone: 'Europe/Berlin',
	})
	return moment(timeZoneCorrectedDate, 'DD.MM.YYYY, HH:mm:ss').format(
		'YYYY-MM-DDTHH:mm:ss'
	)
}

/**
 * Check if the date has the format that the API uses: YYYY-MM-DD
 *
 * @param {string} date - The date as a string
 * @returns {boolean} - Whether the date string has the format
 */
export function checkIfAPIDate(date) {
	return Boolean(date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/))
}

/**
 * Check if the date has the normal European date: DD.MM.YYYY
 *
 * @param {string} date - The date as a string
 * @returns {boolean} - Whether the date string has the format
 */
export function checkIfNormalDate(date) {
	return Boolean(date.match(/[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}/))
}
