import { Dropdown } from 'bootstrap'

/**
 * Preventing the closing of the dropdown if the focus is in the search field (only close the dropdown on focus loss)
 *
 * @param {Event} event - The event object of the hide.bs.dropdown event
 * @param {string} dropdownMenuElementSelector - The selector for the dropdown menu element
 * @param {string} searchInputElementSelector - The selector for the corresponding search input element
 */
export function preventCloseOnFocus(
	event,
	dropdownMenuElementSelector,
	searchInputElementSelector
) {
	const dropDownMenuElement = document.querySelector(
		dropdownMenuElementSelector
	)
	const searchInputElement = document.querySelector(searchInputElementSelector)
	const activeElement = document.activeElement
	if (
		dropDownMenuElement.classList.contains('show') &&
		activeElement === searchInputElement
	) {
		event.preventDefault()
	}
}

/**
 * Defocus the search if esc is pressed
 *
 * @param {Event} event - Default JS onkeyup event object
 * @param {string} dropdownSearchbarSelector -- The selector for the search bar dropbown container element
 */
export function deFocus(event, dropdownSearchbarSelector) {
	if (event.key === 'Escape') {
		const input = event.currentTarget
		input.blur()
	}
	Dropdown.getOrCreateInstance(dropdownSearchbarSelector).hide()
}
