import { onclickElements } from './helpers/eventHelpers'

/**
 * Stores a key value pair as a cookie with optional time to live.
 *
 * @param {string} name - name of the cookie
 * @param {string} value - cookie value
 * @param {number} [daysTillExpired] - days until cookie expires, can be `null`
 */
export function setCookie(name, value, daysTillExpired = null) {
	if (daysTillExpired !== null) {
		const d = new Date()
		d.setTime(d.getTime() + daysTillExpired * 24 * 60 * 60 * 1000)
		const expires = 'expires=' + d.toUTCString()
		document.cookie =
			name + '=' + value + ';' + expires + ';path=/;SameSite=Lax'
	} else {
		document.cookie = name + '=' + value + ';path=/;SameSite=Lax'
	}
}

/**
 * Retrieves a cookie
 *
 * @param {string} name - name of the cookie to retrieve
 * @returns {string} retrieved cookie or `''`
 */
export function getCookie(name) {
	name = name + '='

	// this is needed cause "usedEmails" cookie could contain "%", but "%" crashes in decodeURIComponent, see #13279
	let decodedCookie = ''
	try {
		decodedCookie = decodeURIComponent(document.cookie)
	} catch (error) {
		decodedCookie = document.cookie
		console.error(error)
	}

	const ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

/**
 * try get data from cookies
 *
 * @param {string} cookieString stringified cookie
 * @returns {object} json content from cookie or empty dict
 */
export function tryGetCookieData(cookieString) {
	let data = {}
	try {
		data = JSON.parse(cookieString)
	} catch (error) {
		console.error(`Cant parse cookie | ${error}`)
	}
	return data
}

/**
 * Enables the page to remember the accordion states of each individual accordion in a cookie
 */
export function enableRememberAccordionState() {
	onclickElements({
		'.accordion-button': (event) => rememberAccordionState(event),
	})
}

/**
 * Remembering the state of the accordion so it is not collapsed again when reloading the page
 *
 * @param {Event} event - Default JS onclick ebent object
 */
function rememberAccordionState(event) {
	const accordionButton = event.currentTarget
	const cookie = getCookie('accordionStates') || '{}'
	const accordionStates = tryGetCookieData(cookie)
	let stateName = accordionButton.dataset.bsTarget
	stateName = stateName.replace('#', '')
	accordionStates[stateName] = accordionButton.classList.contains('collapsed')
	setCookie('accordionStates', JSON.stringify(accordionStates))
}
