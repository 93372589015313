import { MaskInput } from 'maska'

/**
 * {
 *  '#': { pattern: /[0-9]/ },
 *  'X': { pattern: /[0-9a-zA-Z]/ },
 *  'S': { pattern: /[a-zA-Z]/ },
 *  'A': { pattern: /[a-zA-Z]/, uppercase: true },
 *  'a': { pattern: /[a-zA-Z]/, lowercase: true },
 *  '!': { escape: true },
 *  '*': { repeat: true }
 * }
 */

/**
 * Creates a mask for a time input field with auto completion
 *
 * Uses 24-hour format and ensures only numbers and a single colon can be entered.
 *
 * @param {string} selector - selector string
 *
 * @returns {object} Maska instance
 */
export function clockInputmask(selector) {
	const mask = new MaskInput(selector, {
		mask: 'h#:m#',
		tokens: {
			h: { pattern: /[0-2]/ },
			m: { pattern: /[0-5]/ },
		},
	})
	const inputElements = document.querySelectorAll(selector)

	inputElements.forEach((inputElement) => {
		inputElement.addEventListener('blur', () => {
			const cleanedInput = inputElement.value.replace(/[^0-9:]/g, '')
			const parts = cleanedInput.split(':')
			let hours = parts[0]
			if (hours) {
				let minutes = parts[1] ? parts[1].substring(0, 2) : ''

				hours = hours.padStart(2, '0')
				minutes = minutes.padEnd(2, '0')

				if (hours.charAt(0) === '2' && parseInt(hours.charAt(1)) > 3) {
					hours = '23'
				}

				inputElement.value = `${hours}:${minutes}`
			}
		})
	})
	return mask
}

/**
 * Create a mask where only numbers are allowed
 *
 * @param {string} selector - selector string
 * @returns {object} Maska instance
 */
export function numberInputMask(selector) {
	return new MaskInput(selector, {
		mask: '0',
		tokens: {
			0: { pattern: /[0-9]/, repeated: true },
		},
	})
}

/**
 * Create a mask where only numbers with a maximum of 8 digits are allowed
 *
 * @param {string} selector - selector string
 * @returns {object} MaskInput instance
 */
export function numberInputMaskEightDigits(selector) {
	return new MaskInput(selector, {
		mask: '00000000', // 8 digits mask
		tokens: {
			0: { pattern: /[0-9]/, repeated: false },
		},
	})
}

/**
 * Create a mask where only numbers and spaces with a maximum of 8 digits are allowed
 *
 * @param {string} selector - selector string
 * @returns {object} MaskInput instance
 */
export function numberInputMaskEightDigitsWithSpaces(selector) {
	return new MaskInput(selector, {
		mask: '0s0s0s0s0s0s0s0',
		tokens: {
			0: { pattern: /[0-9]/, repeated: false },
			s: { pattern: /[\s]/, repeated: false, optional: true },
		},
	})
}

/**
 * Generates an input mask to accept IBAN.
 * See https://de.wikipedia.org/wiki/Internationale_Bankkontonummer#Zusammensetzung for details
 *
 * @param {string} selector - selector of the html element that receives the mask
 * @returns {object | number} - Maska instance or -1 if something fails
 */
export function ibanInputMask(selector) {
	return new MaskInput(selector, {
		mask: (value) => {
			return 'LLPP NNNN NNNN NNNN NNNN NNNN NNNN NNNN NN'
		},
		tokens: {
			L: { pattern: /[a-zA-Z]/, transform: (v) => v.toUpperCase() },
			P: { pattern: /[0-9]/ },
			N: { pattern: /[0-9a-zA-Z]/, transform: (v) => v.toUpperCase() },
		},
	})
}

/**
 * Generates an input mask to accept BIC.
 * See https://de.wikipedia.org/wiki/ISO_9362#Aufbau for details
 *
 * @param {string} selector - selector of the html element that receives the mask
 * @returns {object | number} - Maska instance or -1 if something fails
 */
export function bicInputMask(selector) {
	// See https://de.wikipedia.org/wiki/ISO_9362#Aufbau
	const definitions = {
		B: {
			pattern: /[0-9a-zA-Z]/,
			transform: (v) => v.toUpperCase(),
		},
		C: {
			pattern: /[a-zA-Z]/,
			transform: (v) => v.toUpperCase(),
		},
		L: {
			pattern: /[0-9a-zA-Z]/,
			transform: (v) => v.toUpperCase(),
		},
		b: {
			pattern: /[0-9a-zA-Z]/,
			transform: (v) => v.toUpperCase(),
		},
	}
	return defaultInputMask(selector, 'BBBBCCLLbbb', definitions)
}

/**
 * Generates an input mask to accept BIC.
 * See https://de.wikipedia.org/wiki/ISO_9362#Aufbau for details
 *
 * @param {string} selector - selector of the html element that receives the mask
 * @returns {object | number} - Maska instance or -1 if something fails
 */
export function idNumberInputMask(selector) {
	const definitions = {
		A: {
			pattern: /[a-zA-Z]/,
			transform: (v) => v.toUpperCase(),
		},
		N: {
			pattern: /[0-9a-zA-Z]/,
			transform: (v) => v.toUpperCase(),
		},
	}
	return defaultInputMask(
		selector,
		'AA## NNN NNNN NNNN NNNN NNNN NNNN NNNN NNNN',
		definitions
	)
}

/**
 * Helper that wraps Maska mask creation and does error handling.
 *
 * @param {string} selector - selector of the html element that receives the mask
 * @param {string} mask - the mask you want to use containing '#' as placeholder
 * @param {object} definitions - optional object to define what the placeholders must be
 * @returns {object | number} Maska instance or  -1 if something fails
 */
function defaultInputMask(selector, mask, definitions = undefined) {
	try {
		return new MaskInput(selector, {
			mask: mask,
			tokens: definitions,
		})
	} catch {
		console.error(`Can't create mask for ${selector}`)
		return -1
	}
}

/**
 * Former inputmask that just check for `xx:yy`.
 *
 * @deprecated use `clockInputmask` instead.
 * @param {string} selector - selector string
 * @returns {object} Maska instance
 */
export function timeInputmask(selector) {
	return clockInputmask(selector)
}

/**
 * Create a money input mask with percision of 2
 *
 * @param { string } selector - selector
 * @param { boolean } minusAllowed - flag if negativ values are allowed
 * @returns {MaskInput} maska instance
 */
export function moneyInputMask(selector, minusAllowed = true) {
	if (minusAllowed) {
		return positiveAndNegativeMoneyMask(selector)
	}
	return onlyPositiveMoneyInputMask(selector)
}

/**
 * Creates a money input mask with percision of 2 that only allows positve values.
 *
 * @param { string } selector - selector
 * @returns {MaskInput} maska instance
 */
function onlyPositiveMoneyInputMask(selector) {
	return new MaskInput(selector, {
		mask: '0,99',
		tokens: {
			0: { pattern: /[0-9]/, multiple: true },
			9: { pattern: /[0-9]/, optional: true },
		},
	})
}

/**
 * Creates a money input mask with percision of 2 that only allows positve and negative values.
 *
 * @param { string } selector - selector
 * @returns {MaskInput} maska instance
 */
function positiveAndNegativeMoneyMask(selector) {
	return new MaskInput(selector, {
		mask: (value) => (value.startsWith('-') ? '-0,99' : '0,99'),
		tokens: {
			m: { pattern: /-/, optional: true },
			0: { pattern: /[0-9]/, multiple: true },
			9: { pattern: /[0-9]/ },
		},
	})
}
