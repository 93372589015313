import { Modal } from 'bootstrap'

import { successMessage, getMessageText } from '../globals/messages'
import { alertSuccessConfirmation, alertWarning } from '../globals/alerts'
import { gettext } from '../utils/translation'
import { getContextData } from './django'
import moment from 'moment'
import { show } from './helpers/show'
import { post } from '../api/crud'
import { toggleDisableOnInput } from './helpers/eventHelpers'
// import { get } from '../api/crud'

/**
 * Enables to password reset modal
 */
export function configurePasswordResetModal() {
	const passwordResetModalElement = document.getElementById(
		'footer-passwordChangeModal'
	)
	if (passwordResetModalElement) {
		const myModal = new Modal(passwordResetModalElement, {
			keyboard: false,
			backdrop: 'static',
		})
		myModal.show()
		let lastVerified = getContextData('lastVerified')
		lastVerified = moment(lastVerified, 'YYYY-MM-DDTHH:mm:ss').toDate()
		const now = new Date()
		let targetInputSelectors = '#footer-password, #footer-passwordRepeat'

		if (moment(lastVerified).add(5, 'm').toDate() < now) {
			show('#currentPasswordContainer')
			targetInputSelectors += ', #currentPassword'
			if (getContextData('twoFactorEnabled')) {
				show('#twoFactorContainer')
				targetInputSelectors += ', #twoFactor'
			}
		}

		toggleDisableOnInput(targetInputSelectors, '#footer-updatePassword')

		document
			.getElementById('footer_generatePasswordForGeneratePassword')
			.addEventListener('click', generatePasswordButtonCallback)
		document
			.getElementById('footer-updatePassword')
			.addEventListener('click', () =>
				changeOwnPassword(getContextData('user_id'))
			)
	}
}

/**
 * Changes the own password of the user
 */
async function changeOwnPassword() {
	const password = document.querySelector('#footer-password').value
	if (password !== document.querySelector('#footer-passwordRepeat').value) {
		alertWarning('SEx400xPasswordsNotEqual', undefined, {
			shortDescriptionOnly: true,
		})
		return
	}
	const data = {
		newPassword: password,
	}
	if (
		!document
			.querySelector('#currentPasswordContainer')
			.classList.contains('hidden')
	) {
		data.currentPassword = document.querySelector('#currentPassword').value
	}
	if (
		!document.querySelector('#twoFactorContainer').classList.contains('hidden')
	) {
		data['2FA'] = document.querySelector('#twoFactor').value
	}
	const response = await post(`member/me/change-password`, data)
	successMessage(response.data, successMessageCallback, successMessageCallback)
}

/**
 * Generates and shows a random password.
 */
async function generatePasswordButtonCallback() {
	const password = generatePassword(getContextData('passwordMinimumLength'))
	document
		.querySelectorAll(
			'input[name=footer-password], input[name=footer-passwordRepeat]'
		)
		.forEach((element) => {
			element.value = password
		})
	const keyup = new Event('keyup')
	document.querySelector('#footer-password').dispatchEvent(keyup)
	navigator.clipboard.writeText(password)

	const message = await (await getMessageText('SEx000xPasswordNotSafed')).json()
	const html = `<strong>${
		gettext('Passwort generiert: ') + password
	}</strong> <br><br> ${message.msg}`
	alertSuccessConfirmation(undefined, html)

	// Text inside the SweetAlert is not selectable. Resetting tabindex fixes this
	document
		.querySelector('.swal2-container div[tabindex="-1"]')
		?.setAttribute('tabindex', '')
}

/**
 * Checks response states, in case of the session error we log out. May reload
 */
function successMessageCallback() {
	window.location.reload()
}

/**
 * Generates a password that always meets the criteria: at least 1 capital letter, 1 number, 1 special character
 *
 * @param {number} givenLength - minimum passwordlength
 * @returns {string} password - returns generated password
 */
function generatePassword(givenLength) {
	// usefull flags :)
	// org.passwordMinimumLength
	// org.passwordCapitalLetterRequired
	// org.passwordNumberRequired
	// org.passwordSpecialCharacterRequired
	//
	// to secure a minimum length of 6 characters
	const length = givenLength < 6 ? 6 : givenLength
	const password = (() => {
		const generator = () => {
			const allowedChars =
				'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-.;*+%&$§!?=#'
			let password = ''
			for (let i = 0; i < length; i++) {
				password += allowedChars.charAt(
					Math.floor(Math.random() * allowedChars.length)
				)
			}
			return password
		}
		let passwd = generator()

		while (
			!/[A-Z]/.test(passwd) ||
			!/[0-9]/.test(passwd) ||
			!/[a-z]/.test(passwd) ||
			!/(-|\.|;|\*|\+|%|&|\$|§|!|\?|=|#)/.test(passwd)
		) {
			passwd = generator()
		}
		return passwd
	})()
	return password
}
