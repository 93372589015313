/* eslint-disable no-useless-escape */
export const german = {
	emptyTable: 'Keine Daten in der Tabelle vorhanden',
	info: '_START_ bis _END_ von _TOTAL_ Einträgen',
	infoEmpty: 'Keine Daten vorhanden',
	infoFiltered: '(gefiltert von _MAX_ Einträgen)',
	infoThousands: '.',
	lengthMenu: '_MENU_ Einträge anzeigen',
	loadingRecords: 'Wird geladen ..',
	processing: 'Bitte warten ..',
	paginate: {
		first: 'Erste',
		previous: 'Zurück',
		next: 'Nächste',
		last: 'Letzte',
	},
	aria: {
		sortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
		sortDescending: ': aktivieren, um Spalte absteigend zu sortieren',
	},
	select: {
		rows: {
			_: '%d Zeilen ausgewählt',
			1: '1 Zeile ausgewählt',
		},
		1: '1 Eintrag ausgewählt',
		2: '2 Einträge ausgewählt',
		_: '%d Einträge ausgewählt',
		cells: {
			1: '1 Zelle ausgewählt',
			_: '%d Zellen ausgewählt',
		},
		columns: {
			1: '1 Spalte ausgewählt',
			_: '%d Spalten ausgewählt',
		},
	},
	buttons: {
		print: 'Drucken',
		copy: 'Kopieren',
		copyTitle: 'In Zwischenablage kopieren',
		copySuccess: {
			_: '%d Zeilen kopiert',
			1: '1 Zeile kopiert',
		},
		collection:
			'Aktionen <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span>',
		colvis: 'Spaltensichtbarkeit',
		colvisRestore: 'Sichtbarkeit wiederherstellen',
		copyKeys:
			'Drücken Sie die Taste <i>ctrl</i> oder <i>⌘</i> + <i>C</i> um die Tabelle<br />in den Zwischenspeicher zu kopieren.<br /><br />Um den Vorgang abzubrechen, klicken Sie die Nachricht an oder drücken Sie auf Escape.',
		csv: 'CSV',
		excel: 'Excel',
		pageLength: {
			'-1': 'Alle Zeilen anzeigen',
			1: 'Eine Zeile anzeigen',
			_: '%d Zeilen anzeigen',
		},
		pdf: 'PDF',
	},
	autoFill: {
		cancel: 'Abbrechen',
		fill: 'Alle Zellen mit <i>%d<i> füllen</i></i>',
		fillHorizontal: 'Alle horizontalen Zellen füllen',
		fillVertical: 'Alle vertikalen Zellen füllen',
		info: 'Bitte wählen Sie die gewünschte Aktion aus:',
	},
	decimal: ',',
	search: 'Suche:',
	searchBuilder: {
		add: 'Bedingung hinzufügen',
		button: {
			0: 'Such-Baukasten',
			_: 'Such-Baukasten (%d)',
		},
		clearAll: 'Leeren',
		condition: 'Bedingung',
		conditions: {
			date: {
				after: 'Nach',
				before: 'Vor',
				between: 'Zwischen',
				empty: 'Leer',
				equals: 'Entspricht',
				not: 'Nicht',
				notBetween: 'Nicht zwischen',
				notEmpty: 'Nicht leer',
			},
			moment: {
				after: 'Nach',
				before: 'Vor',
				between: 'Zwischen',
				empty: 'Leer',
				equals: 'Entspricht',
				not: 'Nicht',
				notBetween: 'Nicht zwischen',
				notEmpty: 'Nicht leer',
			},
			number: {
				between: 'Zwischen',
				empty: 'Leer',
				equals: 'Entspricht',
				gt: 'Größer als',
				gte: 'Größer als oder gleich',
				lt: 'Kleiner als',
				lte: 'Kleiner als oder gleich',
				not: 'Nicht',
				notBetween: 'Nicht zwischen',
				notEmpty: 'Nicht leer',
			},
			string: {
				contains: 'Beinhaltet',
				empty: 'Leer',
				endsWith: 'Endet mit',
				equals: 'Entspricht',
				not: 'Nicht',
				notEmpty: 'Nicht leer',
				startsWith: 'Startet mit',
			},
		},
		data: 'Daten',
		deleteTitle: 'Filterregel entfernen',
		leftTitle: 'Äußere Kriterien',
		logicAnd: 'UND',
		logicOr: 'ODER',
		rightTitle: 'Innere Kriterien',
		title: {
			0: 'Such-Baukasten',
			_: 'Such-Baukasten (%d)',
		},
		value: 'Wert',
	},
	searchPanes: {
		clearMessage: 'Leeren',
		collapse: {
			0: 'Suchmasken',
			_: 'Suchmasken (%d)',
		},
		count: '{total}',
		countFiltered: '{shown} ({total})',
		emptyPanes: 'Keine Suchmasken',
		loadMessage: 'Lade Suchmasken..',
		title: 'Aktive Filter: %d',
	},
	searchPlaceholder: 'Durchsuchen',
	thousands: '.',
	zeroRecords: 'Keine passenden Einträge gefunden',
}
