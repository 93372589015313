import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-bs5'

import { gettext, interpolate } from '../utils/translation'
import { getCookie, tryGetCookieData } from '../utils/cookies'
import { numberInputMask } from '../utils/inputmasks'

/**
 * render feature for selecting a specific table page
 * this is executed for all goToTablePage inputs on the page
 * this is used for dataTables
 *
 * @param {string} dataTableId - the id of the DataTable only needed for renderGoToTablePage
 */
export function renderGoToDataTablePage(dataTableId) {
	const wrapper = document.querySelector(
		`.goToTablePage[data-table="${dataTableId}"]`
	)
	if (!wrapper) return

	buildGoToTablePageHTML(wrapper)
}

/**
 * render feature for selecting a specific table page
 * this is used for listings (no dataTables) and only renders a single goToTablePage input
 *
 * @param {string} wrapperId - string of the goToTablePage wrapper
 * @param {number} maxPageNumber - The number of pages in the pagination (only used fpr non dataTables)
 */
export function renderGoToListPage(wrapperId, maxPageNumber) {
	const wrapper = document.querySelector(`#${wrapperId}`)
	if (!wrapper) return

	buildGoToTablePageHTML(wrapper, maxPageNumber)
}

/**
 * build the html for the goToTablePage input
 *
 * @param {HTMLElement} wrapper - the html wrapper element
 * @param {number} maxPageNumber - The number of pages in the pagination (only used fpr non dataTables)
 */
function buildGoToTablePageHTML(wrapper, maxPageNumber = undefined) {
	let _isDataTable = false
	const tableId = wrapper.dataset.table

	if (!maxPageNumber) {
		// is DataTable
		maxPageNumber = $(`#${tableId}`).DataTable().page.info()?.pages || 0
		_isDataTable = true
	}

	let page = 1
	const pageCookie = getCookie(wrapper.dataset.cookie)
	if (pageCookie) {
		page = tryGetCookieData(pageCookie)
	} else if (maxPageNumber === 0) {
		page = 0
	}

	wrapper.innerHTML = `
        <span>
			${interpolate(gettext('Seite %(current)s von %(total)s'), {
				current: `<input type="text" id="goToTablePageInput_${tableId}" class="goToTablePageInput numbermask" placeholder="${page}"/>`,
				total: `<span id="goToTablePageCount_${tableId}">${maxPageNumber}</span>`,
			})}
        </span>
    `
	numberInputMask('.numbermask')

	setGoToTablePageEventListeners(tableId, maxPageNumber, _isDataTable, wrapper)
}

/**
 * set eventListeners for goToTablePage input
 *
 * @param {string} tableId the id of the target table
 * @param {number} maxPageNumber - The number of pages in the pagination (only used fpr non dataTables)
 * @param {boolean} _isDataTable - Is the table a dataTable
 * @param {HTMLElement} wrapper - the html wrapper element
 */
function setGoToTablePageEventListeners(
	tableId,
	maxPageNumber,
	_isDataTable,
	wrapper
) {
	const goToTablePageInput = document.querySelector(
		`#goToTablePageInput_${tableId}`
	)

	const getPage = () => {
		const pageCookie = getCookie(wrapper.dataset.cookie)
		if (pageCookie) {
			return tryGetCookieData(pageCookie)
		} else if (maxPageNumber === 0) {
			return 0
		}
		return 1
	}

	const getTabId = () => {
		const tabHref = document.querySelector('.nav-link.active').href
		const linkFragments = tabHref.split('#')
		if (linkFragments.length) {
			return `#${linkFragments[linkFragments.length - 1].replace('#', '')}`
		}
		return ''
	}

	goToTablePageInput.addEventListener('change', (event) => {
		let newPage = Number(event.currentTarget.value)
		if (isNaN(newPage)) {
			newPage = 1
		}

		if (newPage < 1) {
			newPage = 1
		} else if (newPage > maxPageNumber) {
			newPage = maxPageNumber
		}

		event.currentTarget.value = newPage

		if (_isDataTable) {
			newPage -= 1 // dataTables start with page 0 in paging
			const dataTable = $(`#${tableId}`).DataTable()
			dataTable.page(newPage).draw(false)
		} else {
			document
				.querySelector(`${getTabId()} .gettopage[data-page="${newPage}"]`)
				?.click()
		}
	})

	if (_isDataTable) {
		const dataTable = $(`#${tableId}`).DataTable()
		dataTable.on('page.dt', function () {
			goToTablePageInput.value = dataTable.page.info().page + 1 // dataTables start with page 0 in paging
		})

		// This happens when searching
		dataTable.on('draw.dt', function () {
			const table = $(`#${tableId}`).DataTable()
			setPageData(
				tableId,
				Math.min(table.page() + 1, table.page.info().pages),
				table.page.info().pages
			)
		})
	} else {
		const tabId = getTabId()

		document.querySelector(`${tabId} .next`).addEventListener('click', () => {
			goToTablePageInput.value = getPage() + 1
		})

		document
			.querySelector(`${tabId} .previous`)
			.addEventListener('click', () => {
				goToTablePageInput.value = getPage() - 1
			})

		document.querySelectorAll(`${tabId} .gettopage`).forEach((button) => {
			button.addEventListener('click', (event) => {
				goToTablePageInput.value = event.currentTarget.dataset.page
			})
		})
	}
}

/**
 * Set the number of the current page and the total page number (used e.g. for the datatable search)
 *
 * @param {string} dataTableId - the id of the DataTable
 * @param {number} currentPage - string of the goToTablePage wrapper
 * @param {number} totalPages - The number of pages in the pagination (only used fpr non dataTables)
 */
export function setPageData(dataTableId, currentPage, totalPages) {
	const wrapper = document.querySelector(
		`.goToTablePage[data-table="${dataTableId}"]`
	)
	if (!wrapper) return

	wrapper.querySelector(`#goToTablePageInput_${dataTableId}`).value =
		currentPage
	wrapper.querySelector(`#goToTablePageCount_${dataTableId}`).innerText =
		totalPages
}
