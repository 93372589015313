import $ from 'jquery'
import { gettext } from '../utils/translation'
import { escapeHtml } from '../utils/html'
import { getReadableTextColor } from '../design/colors'

/**
 * Mapping between the kind of group to its internal letter representation.
 */
export const GroupKind = Object.freeze({
	AddressGroup: 'a',
	BookingProject: 'b',
	BankAccount: 'ba',
	Calendar: 'ca',
	InventoryGroup: 'iv',
	TaskGroup: 't',
	UserGroup: 'u',
	InvoiceCall: 'ic',
	ChairmanLevel: 'c',
	OrgUserCustomField: 'e',
	EventCustomField: 'h',
	AddressCustomField: 'j',
	InventoryObjectCustomField: 'i',
	CustomFieldCollection: 'k',
})
/**
 * Fills the projects into the select, changes it to a SelectUs and sets the label listener up.
 *
 * @param {Array<object>} projects - list of project instances from the requestModel API
 * @param {string} defaultName - name of the default "select none" option
 * @param {string} wrapperSelector - selector of the wrapping element to the select [default='.projectSelect']
 */
export function buildProjectSelectUs(
	projects,
	defaultName,
	wrapperSelector = '.projectSelect'
) {
	document.querySelectorAll(wrapperSelector).forEach((container) => {
		if (container.querySelector('select-us')) {
			// If the select-us is already build, skip this container
			return
		}
		const label = container.querySelector('label')
		const select = container.querySelector('select')

		const defaultProject = formatProjectData({
			pk: '-',
			color: '#d3d3d3',
			short: 'NA',
			name: defaultName,
		})
		addOption(select, defaultProject)
		setLabel(label, defaultProject)
		projects.map(formatProjectData).map((data) => addOption(select, data))

		const icon = 'far fa-fw fa-money-bill-wave-alt'
		const textLabel = gettext('Abteilung/Projekte zuordnen')
		const selectUs = `<select-us class="flex-grow-1" fa-icon="${icon}" text-label="${textLabel}">${select.outerHTML}</select-us>`

		select.remove()
		container.insertAdjacentHTML('beforeend', selectUs)

		container
			.querySelector('select-us')
			.addEventListener('selected', (event) => {
				const selected = event.target.selected.pop()
				if (!selected) return
				setLabel(label, selected.dataset)
			})
	})
}

/**
 * Retrieves relevant information from the project
 *
 * @param {object} project - project instance data
 * @returns {object} mapped values
 */
export function formatProjectData(project) {
	return {
		value: project.pk,
		color: project.color,
		short: escapeHtml(project.short),
		text: escapeHtml(project.name),
	}
}

/**
 * Creates an option from `option` and appends it to `select`
 *
 * @param {Element} select - <select> node
 * @param {object} option - option data
 */
function addOption(select, option) {
	const html = `<option value="${option.value}" data-color="${option.color}" data-short="${option.short}">${option.text}</option>`
	select.insertAdjacentHTML('beforeend', html)
}

/**
 * Changes label text and color
 *
 * @param {Element} label - <label> node
 * @param {object} data - <option> dataset
 */
export function setLabel(label, data) {
	label.style.color = getReadableTextColor(data.color)
	label.style.backgroundColor = data.color
	label.innerHTML = data.short
}

/**
 * Builds a group select
 *
 * @deprecated This should not be used, as it uses globals that should not exist
 * @param {object} data - endpoint data
 * @param {string} defaultName - default label value
 * @returns {object} groups[value.pk] = escapeHtml(value.name)
 */
export function buildGroups(data, defaultName) {
	$('.category,.cCategory').css('background-color', '#d3d3d3')
	$('.category,.cCategory').html('NA')
	if (defaultName === undefined) {
		defaultName = `${gettext('Wählen')}`
	}
	$('select[name=group],select[name=cGroup]')
		.not('.chosen-select')
		.html(
			'<option value="-" data-color="#d3d3d3" data-short="NA">-- ' +
				defaultName +
				' --</option>'
		)
	const groups = []
	$(data.data).each(function (key, value) {
		if (value.calendarURL === undefined || value.calendarURL === '') {
			let costCentre = ' -- (KSt: ' + value.projectCostCentre + ')'
			if (value.projectCostCentre === '') {
				costCentre = ''
			}
			if (value.projectCostCentre === undefined) {
				$('select[name=group],select[name=cGroup]').append(
					'<option value="' +
						value.pk +
						'" data-color="' +
						value.color +
						'" data-short="' +
						value.short +
						'">' +
						escapeHtml(value.name) +
						'</option>'
				)
			} else {
				$('select[name=group],select[name=cGroup]').append(
					'<option value="' +
						value.pk +
						'" data-color="' +
						value.color +
						'" data-short="' +
						value.short +
						'">' +
						escapeHtml(value.name) +
						(costCentre || '') +
						'</option>'
				)
			}
		}
		if (typeof groups !== 'undefined' && groups) {
			groups[value.pk] = escapeHtml(value.name)
		}
	})
	return groups
}

/**
 * Legacy utility to get the color for project selects
 *
 * @deprecated Should be avoided
 */
export function registerGroupChange() {
	$('select[name=group],select[name=cGroup]').change(function () {
		let chosenColor = $(this).find('option:selected').data('color')
		$('.category').css('background-color', chosenColor)
		$('.category').html($(this).find('option:selected').data('short'))
		chosenColor = $(this).find('option:selected').data('color')
		$('.cCategory').css('background-color', chosenColor)
		$('.cCategory').html($(this).find('option:selected').data('short'))
	})
}

/**
 * Change the color of the dot (span) inside the label to the color of the selected group
 *
 * @param {object} event - The event object which will be generated if the eventlistener on a element is triggered
 */
export function changeDotColor(event) {
	const select = event.currentTarget
	const selectedOption = select.options[select.selectedIndex]
	const colorPoint = document.querySelector('#colorPoint')

	colorPoint.style.color = selectedOption.dataset.color
}
